// Function to get a value from a dictionary object.
// Used to getting correct translations from EpiServer.
export class DictionaryObject {
  [key: string]: string | DictionaryObject;
}

const Dictionary = (dictionary: DictionaryObject | undefined, path: string, fallback = ''): string => {
  const fallbackText = fallback !== '' ? `[${fallback}]` : fallback;

  if (!dictionary || !path) return fallbackText;

  const pathSplit = path.split('/').filter(item => item != '');
  let result: DictionaryObject | string = dictionary;

  for (let i = 0; i < pathSplit.length; i++) {
    result = typeof result === 'object' ? result[pathSplit[i]] : result;
  }

  if (typeof result === 'undefined' || typeof result === 'object') return fallbackText;

  return result;
};

export default Dictionary;
