import React from 'react';

import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import PlusLarge from '@helsenorge/designsystem-react/components/Icons/PlusLarge';
import Loader from '@helsenorge/designsystem-react/components/Loader';

import SearchHit, { SearchHitProps } from './_SearchHit';
import Dictionary, { DictionaryObject } from '../../_helpers/dictionary';

interface SearchResultsProps {
  currentHitCount: number;
  dictionary: DictionaryObject;
  handleShowMore(): void;
  isMobile: boolean;
  loadingMore: boolean;
  searchHits: SearchHitProps[];
  searchRef: React.MutableRefObject<React.RefObject<HTMLAnchorElement>[]>;
  totalHitCount: number;
}

const SearchResults: React.FunctionComponent<SearchResultsProps> = ({
  currentHitCount,
  dictionary,
  handleShowMore,
  isMobile,
  loadingMore,
  searchHits,
  searchRef,
  totalHitCount,
}) => {
  const renderTotalHitsForQuery = (
    <div className="row no-gutters col-lg-10 offset-lg-1">
      <p className="col-md-10 offset-md-1 my-5">{`${Dictionary(dictionary, '/common/showing', 'Viser')} ${currentHitCount} ${Dictionary(
        dictionary,
        '/common/of',
        'av'
      )} ${totalHitCount} ${Dictionary(dictionary, '/common/hits', 'treff')}`}</p>
    </div>
  );

  const renderSearchHits = searchHits.map((searchHit, index) => {
    return (
      <SearchHit
        isMobile={isMobile}
        searchRef={searchRef?.current[index]}
        dictionary={dictionary}
        index={index}
        intro={searchHit.intro}
        key={index}
        title={searchHit.title}
        url={searchHit.url}
        hitType={searchHit.hitType}
        imageData={searchHit.imageData}
      />
    );
  });

  const renderShowMoreResultsButton = (
    <div className="row no-gutters col-lg-10 offset-lg-1">
      <div className="col-md-10 offset-md-1">
        {loadingMore ? (
          <Loader />
        ) : (
          <Button concept="normal" disabled={loadingMore} variant="outline" onClick={(): void => handleShowMore()}>
            <Icon svgIcon={PlusLarge} />
            {Dictionary(dictionary, '/common/showMore', 'Vis flere')}
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className="row no-gutters">{renderSearchHits}</div>
      {totalHitCount > currentHitCount && (
        <div className="row">
          {renderTotalHitsForQuery}
          {renderShowMoreResultsButton}
        </div>
      )}
    </>
  );
};

export default SearchResults;
