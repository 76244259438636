export const isHidden = (element: HTMLElement): boolean => {
  const containerHeight = element.parentElement?.offsetHeight ?? 0;
  const elementPosition = element.offsetTop;

  return elementPosition > containerHeight;
};

export const isOverflowing = (element: HTMLElement): boolean => {
  return element.clientWidth < element.scrollWidth || element.clientHeight < element.scrollHeight;
};
