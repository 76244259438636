import React, { useEffect, useRef } from 'react';

import ExpanderList, { ExpanderListColors } from '@helsenorge/designsystem-react/components/ExpanderList';
import { TitleTags } from '@helsenorge/designsystem-react/components/Title';

import PortableText from '@helsenorge/core-cms/richtext';

import { useScrollingElementList } from '../../pages/AlphabeticalList/helpers';
import SafeHTML from '../SafeHtml';
import { DELAY_BEFORE_SCROLLING_ON_LOAD_MS } from '../TableOfContents/TableOfContents';

export interface ExpanderProps {
  title: string;
  titleHtmlMarkup?: TitleTags;
  id?: string;
  content?: string;
}

export interface ExpanderListProps {
  color: ExpanderListColors;
  items: ExpanderProps[];
}

const CmsExpanderList: React.FunctionComponent<ExpanderListProps> = ({ color, items }) => {
  const expanderRefList = useRef(items.map(() => React.createRef<HTMLLIElement>()));
  const { scrollToElementByIndex } = useScrollingElementList(expanderRefList);
  const activeId = decodeURI(window.location?.hash);

  useEffect(() => {
    if (activeId) {
      const index = items.findIndex(({ id }) => `#${id}` === activeId);

      if (index !== -1) {
        // Vi må vente litt før scrollingen starter slik at vi scroller til riktig sted
        setTimeout(() => {
          scrollToElementByIndex(index);
        }, DELAY_BEFORE_SCROLLING_ON_LOAD_MS);
        // TODO: ExpanderList støtter ikke p.t. å åpne enkeltelementer i listen,
        // vi må derfor trigge et klikk på knappen i elementet som skal åpnes
        const button = expanderRefList.current[index].current?.querySelector('button');
        if (button) {
          button.focus();
          button.click();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  return (
    <ExpanderList color={color} renderChildrenWhenClosed>
      {items.map(({ title, titleHtmlMarkup, content }, index) => (
        <ExpanderList.Expander title={title} titleHtmlMarkup={titleHtmlMarkup} key={index} ref={expanderRefList.current[index]}>
          {Array.isArray(content) ? (
            <PortableText value={content} backgroundColor={color} />
          ) : (
            content && <SafeHTML html={content} className="rich-text" />
          )}
        </ExpanderList.Expander>
      ))}
    </ExpanderList>
  );
};

export default CmsExpanderList;
