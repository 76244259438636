import React from 'react';

import { IconName } from '@helsenorge/designsystem-react/components/Icons/IconNames';
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import Tile from '@helsenorge/designsystem-react/components/Tile';

export interface TjenesteIconBlockProps {
  title: string;
  linkUrl: string;
  icon: IconName;
  introduction: string;
}

const TjenesteIconBlock: React.FunctionComponent<TjenesteIconBlockProps> = ({ title, linkUrl, icon, introduction }) => {
  return (
    <Tile
      href={linkUrl}
      title={<Tile.Title htmlMarkup="h2">{title}</Tile.Title>}
      description={introduction}
      icon={<LazyIcon iconName={icon} />}
    />
  );
};

export default TjenesteIconBlock;
