import React from 'react';

import Button from '@helsenorge/designsystem-react/components/Button';

interface PrintButtonProps {
  text: string;
}

const PrintButton: React.FunctionComponent<PrintButtonProps> = ({ text }) => (
  <Button onClick={(): void => window.print()} variant="outline" wrapperClassName="d-print-none">
    {text}
  </Button>
);

export default PrintButton;
