import { fetchUtility } from '../_helpers/fetch';

interface EmailResponse {
  status: string;
}

interface EmailRequest {
  date: string;
  sendEmailUrl: string;
  category: string;
  name: string;
  email: string;
  feedback: string;
}

export const sendEmail = ({ date, sendEmailUrl, category, name, email, feedback }: EmailRequest): Promise<EmailResponse | null> =>
  fetchUtility<EmailResponse>(sendEmailUrl, {
    method: 'POST',
    body: JSON.stringify({
      date,
      category,
      name,
      email,
      feedback,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
