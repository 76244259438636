import React from 'react';

import Spacer from '@helsenorge/designsystem-react/components/Spacer';

import LinkListBlock, { LinkListBlockProps } from '@helsenorge/core-cms/linklistblock';
import SearchBox, { SearchBoxProps } from '@helsenorge/core-cms/search';
import { uuid } from '@helsenorge/designsystem-react';

export interface LinkListBlockWithSearchProps extends LinkListBlockProps {
  search?: Required<Pick<SearchBoxProps, 'contentReference' | 'referrer' | 'searchUrl' | 'label' | 'submitLabel'>>;
}

const LinkListBlockWithSearch: React.FunctionComponent<LinkListBlockWithSearchProps> = ({ search, ...rest }) => (
  <LinkListBlock {...rest}>
    {search && <SearchBox {...search} searchInputId={uuid()} isAutoSuggestDisabled={true} isSearchAsYouTypeEnabled={false} large={false} />}
    <Spacer size={'l'} />
  </LinkListBlock>
);

export default LinkListBlockWithSearch;
