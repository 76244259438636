import React from 'react';

import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';
import Expander from '@helsenorge/designsystem-react/components/Expander';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';

interface HowToCiteProps {
  title: string;
  description: string;
  href: string;
}

const HowToCite: React.FunctionComponent<HowToCiteProps> = ({ title, description, href }) => (
  <>
    <Expander title={title} renderChildrenWhenClosed>
      {description} <AnchorLink href={href}>{href}</AnchorLink>
    </Expander>
    <Spacer size="m" />
  </>
);

export default HowToCite;
