export interface UrlParametersObject {
  [key: string]: string | number | null | undefined;
}

export const toUrlParameters = (paramsObject: UrlParametersObject): string => {
  const paramsArray = [];

  for (const param in paramsObject) {
    const paramValue = paramsObject[param];
    if (paramValue != null) {
      paramsArray.push(`${param}=${paramValue}`);
    }
  }

  return `?${paramsArray.join('&')}`;
};
