import { ClinicalTrialsForm } from './ClinicalTrialsBlock';

export const getTimestamp = (): number => Math.floor(Date.now() / 1000);

export const getConceptIdFromUrl = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('diagnose');
};

export const getUrlParams = (): ClinicalTrialsForm => {
  const urlParams = new URLSearchParams(window.location.search);

  return {
    kategori: urlParams.get('kategori') || '',
    status: urlParams.get('status') || '',
    relatertBehandling: urlParams.get('relatertBehandling') || '',
    utfortAv: urlParams.get('utfortAv') || '',
    deltakendeForetak: urlParams.get('deltakendeForetak') || '',
    query: urlParams.get('query') || '',
  };
};

export const setUrlParam = (key: keyof ClinicalTrialsForm, value: string): void => {
  const urlParams = new URLSearchParams(window.location.search);
  if (value) {
    urlParams.set(key, value);
  } else {
    urlParams.delete(key);
  }

  history.pushState(null, '', '?' + urlParams.toString());
};
