import React from 'react';

import classNames from 'classnames';

import Kriseinformasjon, { KriseinformasjonProps } from './Kriseinformasjon';

interface KrisePanelProps {
  kriseinformasjon: KriseinformasjonProps | null;
}

const KrisePanel: React.FunctionComponent<KrisePanelProps> = ({ kriseinformasjon }) => {
  return <>{!!kriseinformasjon && <Kriseinformasjon {...kriseinformasjon} className={classNames('mt-8')} />}</>;
};

export default KrisePanel;
