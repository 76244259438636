import { PaletteNames } from '@helsenorge/designsystem-react/theme/palette';

import { theme } from '@helsenorge/designsystem-react';

export const getIconColor = (name: PaletteNames): string => {
  if (name === 'black') {
    return theme.palette.black;
  } else if (name === 'white') {
    return theme.palette.white;
  } else if (name === 'plum') {
    return theme.palette.plum600;
  } else if (name === 'neutral') {
    return theme.palette.neutral600;
  } else if (name === 'kiwi') {
    return theme.palette.kiwi600;
  } else if (name === 'cherry') {
    return theme.palette.cherry600;
  } else if (name === 'blueberry') {
    return theme.palette.blueberry600;
  } else if (name === 'banana') {
    return theme.palette.banana600;
  }
  return theme.palette.black;
};
