import { useState, useRef, useEffect } from 'react';

import { animateScroll } from 'react-scroll';

import { Heading } from './getHeadingList';
import { prefersReducedMotion } from './utils';

/**
 * Avstand i px mellom bunnen av innholdsfortegnelsen og overskriften man skroller til.
 * Sørger for at overskriften man skroller til ikke blir liggende under eller for tett på
 * innholdsfortegnelsen.
 */
const SPACING_BETWEEN_TOC_AND_HEADING_PX = 8;
/**
 * Avstand over overskrifter som skal telle som en del av overskriften.
 * Sørger for at innholdsfortegnelsen viser riktig overskrift mens man skroller manuelt.
 */
const MARGIN_ABOVE_HEADING_PX = 48;

/**
 * Hvor lang tid skal det ta å scrolle
 */
const SCROLL_DURATION_MS = 500;

/**
 * Håndterer scrolling i liste med overskrifter
 * @param headingList Liste med overskrifter
 * @param offsetRef Element som må tas hensyn til ved beregning av scrolledistanse
 * @param stickyRef Element som skal settes sticky
 * @returns
 */
export const useScrollingHeadingList = (
  headingList: Heading[],
  offsetRef?: React.RefObject<HTMLElement>,
  stickyRef?: React.RefObject<HTMLElement>
): {
  currentHeading: Heading | undefined;
  scrollToHeading: (heading: Heading, immediate?: boolean) => void;
  scrollToElement: (element: HTMLElement, immediate?: boolean) => void;
  isSticky: boolean | undefined;
} => {
  const ticking = useRef(false);
  const [currentHeading, setCurrentHeading] = useState<Heading>();
  const [isSticky, setIsSticky] = useState<boolean>();

  const scrollToElement = (element: HTMLElement, immediate = false): void => {
    const heightOfOffsetElement = offsetRef?.current?.getBoundingClientRect().height ?? 0;
    const distanceFromWindowToElement = element.getBoundingClientRect().top ?? 0;
    const scrollDuration = immediate ? 0 : SCROLL_DURATION_MS;
    const toY = window.scrollY + distanceFromWindowToElement - heightOfOffsetElement - SPACING_BETWEEN_TOC_AND_HEADING_PX;
    // 1. Oppdater URLen mtp. bokmerking
    history.replaceState({ slug: element.id }, document.title, `#${element.id}`);
    // 2. Scroll til overskriften
    animateScroll.scrollTo(toY, {
      duration: scrollDuration,
      smooth: !immediate && !prefersReducedMotion(),
    });
  };

  const scrollToHeading = (heading: Heading, immediate = false): void => {
    const heightOfOffsetElement = offsetRef?.current?.getBoundingClientRect().height ?? 0;
    const distanceFromWindowToElement = heading.element?.getBoundingClientRect().top ?? 0;
    const scrollDuration = immediate ? 0 : SCROLL_DURATION_MS;
    // 1. Oppdater URLen mtp. bokmerking
    history.replaceState({ slug: heading.slug }, document.title, `#${heading.slug}`);
    // 2. Sett fokus på overskriften som er valgt
    setTimeout(() => {
      heading.element?.focus();
    }, scrollDuration);
    // 3. Scroll til overskriften
    const toY = window.scrollY + distanceFromWindowToElement - heightOfOffsetElement - SPACING_BETWEEN_TOC_AND_HEADING_PX;
    animateScroll.scrollTo(toY, {
      duration: scrollDuration,
      smooth: !immediate && !prefersReducedMotion(),
    });
  };

  useEffect(() => {
    const handleScroll = (): void => {
      ticking.current = false;

      const heightOfOffsetElement = offsetRef?.current?.getBoundingClientRect().height ?? 0;
      const heading = headingList
        .slice()
        .reverse()
        .find(link => {
          const { bottom: distanceToBottomOfElement = 0, height: heightOfElement = 0 } = link.element?.getBoundingClientRect() ?? {};

          return distanceToBottomOfElement < heightOfElement + heightOfOffsetElement + MARGIN_ABOVE_HEADING_PX;
        });
      setCurrentHeading(heading);

      const stickyRefRect = stickyRef?.current?.parentElement?.getBoundingClientRect();
      const sticky = (stickyRefRect?.top ?? 0) + (stickyRefRect?.height ?? 0) <= 0;
      setIsSticky(sticky);
    };

    const onScroll = (): void => {
      // Throttle the event listener to around 60 fps (or whatever the web browser thinks is OK)
      if (!ticking.current) {
        requestAnimationFrame(handleScroll);
      }
      ticking.current = true;
    };

    window.addEventListener('scroll', onScroll);

    return (): void => window.removeEventListener('scroll', onScroll);
  }, [offsetRef, headingList, stickyRef]);

  return { currentHeading, scrollToHeading, scrollToElement, isSticky };
};
