import React, { useRef, useEffect, useMemo } from 'react';

import classNames from 'classnames';

import Title from '@helsenorge/designsystem-react/components/Title';
import Tooltip, { TooltipOpenProvider } from '@helsenorge/designsystem-react/components/Tooltip';

import PortableText from '@helsenorge/core-cms/richtext';
import { PortableTextBlocks } from '@helsenorge/core-cms/richtext/portable-text-utils';

import AlphabetPanel from '../AlphabeticalList/AlphabetPanel';
import { useScrollingElementList } from '../AlphabeticalList/helpers';

interface Link {
  title: string;
  wordExplanation: PortableTextBlocks;
}

export interface LetterGroup {
  letter: string;
  ordforklaringList: Link[];
}

interface AlphabeticalListProps {
  alphabetPanelButtonText: string;
  alphabet: string;
  alphabetPanelTitle: string;
  linksForLetter: string;
  hideAlphabetPanel: boolean;
  letterGroupList: LetterGroup[];
}

interface OrdforklaringProps {
  link: Link;
}

const Ordforklaring: React.FC<OrdforklaringProps> = ({ link }) => (
  <Tooltip description={<PortableText value={link.wordExplanation} />}>{link.title}</Tooltip>
);

const OppslagsverkPage: React.FunctionComponent<AlphabeticalListProps> = ({
  alphabetPanelButtonText,
  alphabet,
  alphabetPanelTitle,
  linksForLetter,
  hideAlphabetPanel,
  letterGroupList,
}) => {
  const letterList = letterGroupList.map(letterGroup => letterGroup.letter.toUpperCase());
  const alphabetLetterList = useMemo(() => [...alphabet], [alphabet]);
  const alphabetPanelRef = useRef<HTMLDivElement>(null);

  const letterGroupRefList = useRef(letterList.map(() => React.createRef<HTMLDivElement>()));

  const { attributeValue: currentLetter, scrollToElementByIndex } = useScrollingElementList(
    letterGroupRefList,
    alphabetPanelRef,
    'data-letter'
  );

  useEffect(() => {
    history.replaceState({ letter: currentLetter }, document.title, `#${currentLetter}`);
  }, [alphabetLetterList, currentLetter]);

  const handleLetterClick = (event: React.MouseEvent<HTMLAnchorElement>, letter: string): void => {
    event.preventDefault();
    window.history.pushState({ letter }, document.title, event.currentTarget.href);
    const index = letterList.findIndex(x => x === letter);
    scrollToElementByIndex(index);
  };

  const renderLinkList = (linkList: Link[]): JSX.Element => {
    return (
      <TooltipOpenProvider>
        <ul className={classNames('alphabet-link-list mb-0')}>
          {linkList.map((link, index) => {
            return (
              <li key={index} className="alphabet-link-list__item">
                <Ordforklaring link={link} />
              </li>
            );
          })}
        </ul>
      </TooltipOpenProvider>
    );
  };

  const renderLinkGroupList = (): JSX.Element => (
    <div className={'container'}>
      <div className={'py-8'}>
        {letterGroupList.map(({ letter, ordforklaringList }, index) => (
          <div className="row" key={letter}>
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <div className="border-bottom-1 border-color-neutral500 pb-6 mb-6">
                <nav aria-label={`${linksForLetter} ${letter}`} ref={letterGroupRefList.current[index]} data-letter={letter}>
                  <Title htmlMarkup="h2" appearance="title2" id={letter.toUpperCase()} className="text-blueberry600">
                    {letter}
                  </Title>
                  {ordforklaringList && renderLinkList(ordforklaringList)}
                </nav>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      {!hideAlphabetPanel && (
        <AlphabetPanel
          alphabetPanelTitle={alphabetPanelTitle}
          alphabetPanelButtonText={alphabetPanelButtonText}
          handleLetterClick={handleLetterClick}
          currentLetter={currentLetter}
          letterList={letterList}
          alphabetLetterList={alphabetLetterList}
          ref={alphabetPanelRef}
        />
      )}
      {letterGroupList && renderLinkGroupList()}
    </div>
  );
};

export default OppslagsverkPage;
