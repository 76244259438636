import React from 'react';

const ChevronUpWhiteLine: React.FC = () => (
  <>
    <path fill="#fff" d="M12.21 20.92h13.58v3.71H12.21z" />
    <path fillRule="evenodd" d="m25.79 23.78 1.42-1.41L19 14.1l-8.21 8.27 1.42 1.41L19 16.94l6.79 6.84z" />
    <path fill="none" d="M0 0h38v38H0z" />
  </>
);

export default ChevronUpWhiteLine;
