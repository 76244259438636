/* eslint-disable react/prop-types */
import { PortableTextTypeComponent } from '@portabletext/react';

import ResourceBlockComponent, { ResourceBlockProps } from '@helsenorge/core-cms/behandlingsvalg/ressursblock';

export interface ResourceBlockType extends ResourceBlockProps {
  _type: 'ResourceBlock';
}

const ResourceBlock: PortableTextTypeComponent<ResourceBlockType> = ({ value }) => <ResourceBlockComponent {...value} />;

export default ResourceBlock;
