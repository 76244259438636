import React from 'react';

import classnames from 'classnames';

import ResourceBlock, { Resource, ResourceDisplay } from '@helsenorge/core-cms/behandlingsvalg/ressursblock';
import styles from '@helsenorge/core-cms/behandlingsvalg/ressursblock/styles.module.scss';

interface ResourceBlockContainerProps {
  resourceList: Resource[];
  display: ResourceDisplay;
}

const ResourceBlockContainer: React.FunctionComponent<ResourceBlockContainerProps> = ({ resourceList, display }) => (
  <div className={classnames(styles.resource, styles[`resource--${display}`])}>
    {resourceList.map((resource, index) => (
      <ResourceBlock key={index} resource={{ ...resource, display: 'none' }} className={index > 0 ? 'mt-8' : undefined} />
    ))}
  </div>
);

export default ResourceBlockContainer;
