import React from 'react';

import classnames from 'classnames';

import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import ChevronRight from '@helsenorge/designsystem-react/components/Icons/ChevronRight';
import Title from '@helsenorge/designsystem-react/components/Title';

import { getIconColor } from '../../_helpers/color';
import Dictionary, { DictionaryObject } from '../../_helpers/dictionary';

interface SearchDidYouMeanProps {
  dictionary: DictionaryObject;
  results?: string[] | null;
  triggerDidYouMean(query: string): void;
}

const SearchDidYouMean: React.FunctionComponent<SearchDidYouMeanProps> = ({ dictionary, results, triggerDidYouMean }) => {
  return (
    <>
      {!!results?.length && (
        <div className="search-dym row no-gutters">
          <div className="col-lg-10 offset-lg-1 my-6 row no-gutters">
            <div className="col-md-10 offset-md-1">
              <Title htmlMarkup="h2" appearance="title3" className="mb-5">
                {Dictionary(dictionary, '/searchPage/didYouMean', 'Did you mean...')}
              </Title>
              {results.map((suggestion, index) => (
                <button
                  className={classnames('border-bottom-1', {
                    'border-top-1': index === 0,
                  })}
                  type="button"
                  key={`${suggestion}-${index}`}
                  onClick={(): void => triggerDidYouMean(suggestion)}
                >
                  {suggestion}
                  <Icon size={IconSize.XSmall} color={getIconColor('black')} svgIcon={ChevronRight} />
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchDidYouMean;
