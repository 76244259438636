import Button from '@helsenorge/designsystem-react/components/Button';
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import ServiceMessage from '@helsenorge/designsystem-react/components/ServiceMessage';

// Expose designsystem to Razor views
globalThis.HNDS = {
  Button,
  ServiceMessage,
  LazyIcon,
};
