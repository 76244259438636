import React, { useRef, useState } from 'react';

import classNames from 'classnames';

import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';
import Button from '@helsenorge/designsystem-react/components/Button';
import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import ChevronDown from '@helsenorge/designsystem-react/components/Icons/ChevronDown';
import ChevronUp from '@helsenorge/designsystem-react/components/Icons/ChevronUp';
import Globe from '@helsenorge/designsystem-react/components/Icons/Globe';

import { mapCultureNameToLanguageLocale } from '@helsenorge/core-cms/shared/language';
import { theme, useLayoutEvent } from '@helsenorge/designsystem-react';
import { setUserLanguage } from '@helsenorge/framework-utils/hn-language';

import { isHidden, isOverflowing } from './language-selector-utils';

import styles from './styles.module.scss';

interface LanguageVersion {
  language: string;
  cultureName: string;
  resolvedUrl: string;
  isCurrentLanguage: boolean;
}

export interface LanguageSelectorProps {
  languageVersions: LanguageVersion[];
  showMore: string;
  showLess: string;
  ariaLabel: string;
}

const LanguageSelector: React.FunctionComponent<LanguageSelectorProps> = ({ languageVersions, showMore, showLess, ariaLabel }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showToggleButton, setShowToggleButton] = useState<boolean>(true);
  const languageListRef = useRef<HTMLUListElement>(null);
  const currentLanguage = languageVersions.find(version => version.isCurrentLanguage);

  const languageList = languageVersions.filter(version => !version.isCurrentLanguage);

  const toggleShowAll = (): void => setExpanded(!expanded);

  // Brukes til å vise alle språk dersom innbygger tabber gjennom listen
  // og tabber til et språk som er skjult
  const handleLanguageLinkFocus = (event: React.FocusEvent<HTMLLIElement>): void => {
    if (isHidden(event.target) && !expanded) {
      setExpanded(true);
    }
  };

  const setToggleButtonVisibility = (): void => {
    if (languageListRef?.current && isOverflowing(languageListRef.current)) {
      setShowToggleButton(true);
    } else if (!expanded) {
      setShowToggleButton(false);
    }
  };

  useLayoutEvent(setToggleButtonVisibility);

  const handleLanguageChange = (cultureName: string): void => {
    const locale = mapCultureNameToLanguageLocale(cultureName);
    if (locale) {
      setUserLanguage(locale);
    }
  };

  return (
    <nav className={styles.languageselector} aria-label={ariaLabel}>
      {showToggleButton && (
        <div className={styles['languageselector__button-wrapper']}>
          <Button
            onClick={toggleShowAll}
            variant="borderless"
            aria-expanded={expanded}
            ariaLabel={expanded ? (showLess ?? 'Show less') : (showMore ?? 'Show more')}
          >
            <Icon svgIcon={expanded ? ChevronUp : ChevronDown} className="mr-0 ml-3" />
          </Button>
        </div>
      )}

      <ul
        ref={languageListRef}
        className={classNames(styles.languageselector__list, expanded && styles['languageselector__list--expanded'])}
      >
        <li className={classNames(styles.languageselector__list__item, styles['languageselector__list__item--default'])}>
          <Icon svgIcon={Globe} color={theme.palette.blueberry600} size={IconSize.XSmall} className={styles.languageselector__icon} />
          {currentLanguage?.language}
        </li>
        {languageList.map(languageVersion => (
          <li key={languageVersion.cultureName} className={styles['languageselector__list__item']} onFocus={handleLanguageLinkFocus}>
            <AnchorLink href={languageVersion.resolvedUrl} onClick={(): void => handleLanguageChange(languageVersion.cultureName)}>
              {languageVersion.language}
            </AnchorLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default LanguageSelector;
