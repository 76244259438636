import React, { useState, useEffect, useRef } from 'react';

import classnames from 'classnames';

import { shouldShowChatbotButton } from './logic';
import { ConversationStore, ButtonStore } from './store';

import styles from './styles.module.scss';

interface ChatPanelEvent {
  detail: Record<string, string>;
}
interface BoostChatPanel {
  addEventListener: (event: string, listener: (event: ChatPanelEvent) => unknown) => void;
  show: () => void;
}

declare global {
  interface Window {
    boostChatPanel?: (options: Record<string, boolean | string | string[] | null>) => BoostChatPanel;
  }
}

interface ChatbotButtonProps {
  chatPanelApiUrl: string;
  chatbotButtonText: string;
  chatbotButtonIcon: string;
  removeChatbotButtonText: string;
  chatbotFilter?: string;
  chatbotTitle: string;
  landmarkLabel: string;
}

const ChatbotButton: React.FunctionComponent<ChatbotButtonProps> = ({
  chatPanelApiUrl,
  chatbotButtonText,
  chatbotButtonIcon,
  removeChatbotButtonText,
  chatbotFilter = 'privatperson',
  chatbotTitle,
  landmarkLabel,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showButton, setShowButton] = useState(false);
  const [chatPanel, setChatPanel] = useState<BoostChatPanel>();

  const hideButton = (): void => {
    ButtonStore.hide();
    setShowButton(false);
  };

  const showChat = (): void => {
    chatPanel?.show();
  };

  /**
   * The chatPanel.js script creates the global window-variable 'boostChatPanel'.
   * This method populates this variable with the static configuration in 'CHAT_PANEL_CONFIGURATION'
   * and url to boost.ai's API. Also sets event listeners for the chatPanel object.
   */
  const createChatPanel = (): void => {
    if (window.boostChatPanel) {
      const chatPanelObject = window.boostChatPanel({
        hyperlinksTargetBlank: true,
        pace: 'supersonic',
        zIndex: '100000',
        filterValues: [chatbotFilter],
        title: chatbotTitle,
        apiUrlBase: chatPanelApiUrl,
        conversationId: ConversationStore.getId(),
      });

      ['chatPanelClosed', 'chatPanelMinimized'].forEach(eventName => {
        chatPanelObject.addEventListener(eventName, () => {
          buttonRef.current?.focus();
        });
      });
      chatPanelObject.addEventListener('conversationIdChanged', event => {
        ConversationStore.start(event.detail.conversationId);
      });

      setChatPanel(chatPanelObject);
    }
  };

  useEffect(() => {
    ConversationStore.checkExpiration();
    if (shouldShowChatbotButton()) {
      setShowButton(true);
      createChatPanel();
    } else {
      setShowButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!showButton) {
    return null;
  }

  return (
    <aside className={classnames(styles.chatbot, 'd-print-none')} aria-label={landmarkLabel}>
      <button ref={buttonRef} onClick={(): void => showChat()} type="button" className={styles.chatbot__button}>
        <img src={chatbotButtonIcon} className={styles.chatbot__icon} alt="" />
        {chatbotButtonText}
      </button>
      <button onClick={(): void => hideButton()} type="button" aria-label={removeChatbotButtonText} className={styles.chatbot__close}>
        <span className={classnames(styles.close, styles.close__one)}></span>
        <span className={classnames(styles.close, styles.close__two)}></span>
      </button>
    </aside>
  );
};

export default ChatbotButton;
