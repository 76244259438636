import React from 'react';

import { useIsServerSide } from '@helsenorge/designsystem-react/hooks/useIsServerSide';

import { sanitize } from '@helsenorge/framework-utils/trusted-types';

interface SafeHTMLProps extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'className' | 'aria-live'> {
  children?: React.ReactNode;
  /** Tekst som vil bli sanitized og rendret inn med dangerouslySetInnerHTML */
  html: string;
  /** Setter data-testid-attributt */
  testId?: string;
}

const SafeHTML: React.FC<SafeHTMLProps> = ({ html, testId, className, children, ...rest }) => {
  const isServerSide = useIsServerSide();

  return (
    // eslint-disable-next-line react/no-danger
    <div className={className} data-testid={testId} dangerouslySetInnerHTML={{ __html: isServerSide ? html : sanitize(html) }} {...rest}>
      {children}
    </div>
  );
};

export default SafeHTML;
