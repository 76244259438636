import { useEffect } from 'react';

export const useKeyDown = (ref: React.RefObject<HTMLElement>, key: string | string[], callback: (event: KeyboardEvent) => void): void => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === key || (Array.isArray(key) && key.includes(event.key))) {
        callback(event);
      }
    };
    ref?.current?.addEventListener('keydown', handleKeyDown);

    return (): void => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref?.current?.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, callback]);
};
