import React from 'react';

interface IconProps {
  className?: string;
  color: string;
}

const NoHitsIcon = React.forwardRef<SVGSVGElement, IconProps>((svgProps, ref) => {
  const { className, color = 'black', ...props } = svgProps;
  const normal = (
    <g>
      <path d="M194.18,241.3A79.87,79.87,0,0,0,208,208.7a1.61,1.61,0,0,0,.78-.24,42.25,42.25,0,0,1,5.9-3.29,1.5,1.5,0,1,0-1.23-2.74,44.78,44.78,0,0,0-4.9,2.62,78.54,78.54,0,0,0,.69-10.46,80.24,80.24,0,1,0-33.65,65.24L212.75,297a13.13,13.13,0,1,0,18.56-18.57Zm-65.09,30.44a77.27,77.27,0,1,1,44.34-14.06A77.24,77.24,0,0,1,129.09,271.74Z" />
      <path d="M133.48,194.51a8.41,8.41,0,0,0,6.29,2c-2.33,4.88-5,12.37-2.4,16.67,1.3,2.12,3.62,3.19,6.91,3.19.4,0,.82,0,1.25-.05,4-.3,6.55-1.75,7.61-4.32a6.43,6.43,0,0,0,.47-2.1,9.71,9.71,0,0,0,7-.21,43.75,43.75,0,0,0,3.56,4.24,1.51,1.51,0,0,0,2.12.05,1.5,1.5,0,0,0,.05-2.12,42.38,42.38,0,0,1-3.43-4.11c1.58-2.28,1.43-5.56-.16-8.75a6.82,6.82,0,0,0,1.93-.79c2.38-1.47,3.44-4.21,3.14-8.17s-1.75-6.55-4.31-7.61c-4.64-1.91-11.63,2.08-16.1,5.24a8.39,8.39,0,0,0-3-6,8.47,8.47,0,1,0-11,12.89Zm31.38-4.26c.2,2.75-.37,4.56-1.72,5.39a4.67,4.67,0,0,1-2.08.62c-3.8.33-9.85-2.09-14.2-4.43.91-.71,1.9-1.44,2.95-2.16,4.45-3,9.76-5.64,12.58-4.47C163.82,185.79,164.65,187.49,164.86,190.25Zm-14.5,20.6c-.59,1.43-2.29,2.26-5.05,2.47s-4.56-.38-5.39-1.72c-1.63-2.65.08-8.38,2.32-13.26h0c.48-1.06,1-2.07,1.49-3,3.07,3.91,6.52,9.48,6.88,13.3A4.7,4.7,0,0,1,150.36,210.85Z" />
      <path d="M101.42,210.9h11.73a1.5,1.5,0,0,0,0-3H101.42a1.5,1.5,0,1,0,0,3Z" />
      <path d="M86.8,213.69a1.5,1.5,0,0,0,1.5,1.5h4.87a1.5,1.5,0,1,0,0-3H88.3A1.5,1.5,0,0,0,86.8,213.69Z" />
      <path d="M126.24,230h6.1a1.5,1.5,0,1,0,0-3h-6.1a1.5,1.5,0,0,0,0,3Z" />
      <path d="M310.6,188.63a48.63,48.63,0,0,0-6.41-3.33,1.52,1.52,0,0,0-2,.81,1.5,1.5,0,0,0,.81,2,45.47,45.47,0,0,1,6,3.12,1.51,1.51,0,0,0,2.07-.5A1.5,1.5,0,0,0,310.6,188.63Z" />
      <path d="M220.71,203.2l.27,0a25.14,25.14,0,0,1,5.56-.39c.34,0,.68,0,1,.06a1.5,1.5,0,1,0,.25-3l-1.14-.07a28,28,0,0,0-6.22.44,1.5,1.5,0,0,0,.27,3Z" />
      <path d="M274.65,195.65a1.5,1.5,0,0,0-2.08.4c-.52.77-1,1.54-1.53,2.31-.78,1.18-1.55,2.36-2.35,3.5a1.5,1.5,0,0,0,2.46,1.72c.81-1.17,1.6-2.37,2.4-3.58l1.5-2.27A1.49,1.49,0,0,0,274.65,195.65Z" />
      <path d="M264.42,207a15.64,15.64,0,0,1-5.32,3.46,1.5,1.5,0,0,0,.54,2.9,1.39,1.39,0,0,0,.53-.1,18.59,18.59,0,0,0,6.33-4.09,1.5,1.5,0,0,0-2.08-2.17Z" />
      <path d="M297.17,183.2a23.37,23.37,0,0,0-7.45-.12,1.5,1.5,0,0,0,.46,3,20.23,20.23,0,0,1,6.48.12l.26,0a1.5,1.5,0,0,0,.25-3Z" />
      <path d="M282.64,185.57a23.6,23.6,0,0,0-5.71,4.75,1.5,1.5,0,0,0,.11,2.12,1.52,1.52,0,0,0,2.12-.11,20.57,20.57,0,0,1,5-4.17,1.51,1.51,0,0,0,.54-2A1.49,1.49,0,0,0,282.64,185.57Z" />
      <path d="M241.44,205A31.5,31.5,0,0,0,235,201.5a1.5,1.5,0,0,0-1.09,2.79,29.53,29.53,0,0,1,5.84,3.18,1.44,1.44,0,0,0,.84.26,1.5,1.5,0,0,0,.84-2.74Z" />
      <path d="M253,211.3a13.94,13.94,0,0,1-5.82-2.32,1.5,1.5,0,0,0-1.61,2.53,16.8,16.8,0,0,0,7.06,2.77h.19a1.5,1.5,0,0,0,.18-3Z" />
      <path d="M129.09,136.4a58.16,58.16,0,1,0,58.17,58.19A58.26,58.26,0,0,0,129.09,136.4Zm55.17,58.19a54.83,54.83,0,0,1-6,25,1.5,1.5,0,0,0-.92-.72,21,21,0,0,1-5.9-2.71,1.5,1.5,0,1,0-1.65,2.5,23.73,23.73,0,0,0,6.76,3.1,1.44,1.44,0,0,0,.4.06h.11a55.16,55.16,0,1,1,7.19-27.2Z" />
      <path d="M160.66,77.66a1.5,1.5,0,0,0,1.5-1.5V58.65a1.5,1.5,0,0,0-3,0V76.16A1.5,1.5,0,0,0,160.66,77.66Z" />
      <path d="M197.38,90.89a1.51,1.51,0,0,0,1.11-.48l14.94-16.23a1.5,1.5,0,1,0-2.2-2L196.28,88.38a1.49,1.49,0,0,0,1.1,2.51Z" />
      <path d="M240.6,110.57l-21.35,5a1.5,1.5,0,0,0,.34,3,2,2,0,0,0,.34,0l21.36-5a1.5,1.5,0,0,0-.69-2.92Z" />
    </g>
  );

  return (
    <svg width="100%" height="100%" fill={color} viewBox="0 0 350 400" ref={ref} className={className} {...props}>
      {normal}
    </svg>
  );
});

NoHitsIcon.displayName = 'NoHitsIcon';

export default NoHitsIcon;
