import React, { useState, useEffect } from 'react';

import Title from '@helsenorge/designsystem-react/components/Title';

import Panel from '@helsenorge/core-cms/panel';

import { getBehandlingItem, Behandling } from '../../_api/VentetidApi';
import Dictionary, { DictionaryObject } from '../../_helpers/dictionary';

interface VentetidBlockProps {
  id: number;
  dictionary: DictionaryObject;
  ventetidUrl: string;
  ventetidApiUrl: string;
}

const VentetidBlock: React.FunctionComponent<VentetidBlockProps> = ({ id, dictionary, ventetidUrl, ventetidApiUrl }) => {
  const [error, setError] = useState<string>();
  const [loaded, setLoaded] = useState(false);
  const [behandling, setBehandling] = useState<Behandling>();

  useEffect(() => {
    getBehandlingItem(id, ventetidApiUrl)
      .then(behandling => {
        if (behandling) {
          setBehandling(behandling);
        }
        setLoaded(true);
      })
      .catch(() => setError(Dictionary(dictionary, '/ventetidBlock/loadingError', 'Feil: Kunne ikke laste inn ventetider')));
  }, [dictionary, id, ventetidApiUrl]);

  if (error) return <p className="paragraph text-cherry500 is-semibold">{error}</p>;

  if (!ventetidUrl)
    return (
      <p className="paragraph">
        {Dictionary(dictionary, '/ventetidBlock/urlUndefinedError', 'Feil: URL til side med ventetider er ikke definert')}
      </p>
    );

  if (!loaded || !behandling || behandling.antallBehandlingssteder === 0) return null;

  const title =
    behandling.antallBehandlingssteder === 1
      ? Dictionary(dictionary, '/ventetidBlock/singleBehandlingssted', 'behandlingssted for') + ` ${behandling.navn}`
      : Dictionary(dictionary, '/ventetidBlock/multipleBehandlingssteder', 'behandlingssteder for') + ` ${behandling.navn}`;

  const intro =
    behandling.ventetidMin === behandling.ventetidMax && behandling.ventetidMin === 1
      ? `${behandling.ventetidMin} ` + Dictionary(dictionary, '/ventetidBlock/singleWeekWaitingTime', 'ukes ventetid')
      : `${behandling.ventetidMin}-${behandling.ventetidMax} ` +
        Dictionary(dictionary, '/ventetidBlock/multipleWeeksWaitingTime', 'ukers ventetid');

  const count = (
    <Title htmlMarkup="span" appearance="title1">
      {behandling.antallBehandlingssteder}
    </Title>
  );

  return (
    <Panel
      url={`${ventetidUrl}?bid=${id}`}
      emphasize={count}
      title={title}
      linkTitle={`${behandling.antallBehandlingssteder} ${title}`}
      intro={intro}
    />
  );
};

export default VentetidBlock;
