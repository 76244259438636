import { fetchUtility } from '../_helpers/fetch';

export interface Behandling {
  navn: string;
  antallBehandlingssteder: number;
  ventetidMin: number;
  ventetidMax: number;
  httpStatusCode: number;
}

export const getBehandlingItem = (id: number, endpoint: string): Promise<Behandling | null> =>
  fetchUtility<Behandling>(`${endpoint}?behandlingId=${id}`);
