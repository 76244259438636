import React, { useRef, useState, useEffect } from 'react';

import cn from 'classnames';

import Button from '@helsenorge/designsystem-react/components/Button';
import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import ChevronDown from '@helsenorge/designsystem-react/components/Icons/ChevronDown';
import ChevronUp from '@helsenorge/designsystem-react/components/Icons/ChevronUp';
import List from '@helsenorge/designsystem-react/components/Icons/List';

import useClickOutside from '@helsenorge/core-utils/click-outside';
import { useHover, useLayoutEvent, usePrevious, useSize } from '@helsenorge/designsystem-react';
import { trackNavigation } from '@helsenorge/framework-utils/adobe-analytics';
import { getSidetittelId } from '@helsenorge/framework-utils/hn-page';

import { Heading } from './getHeadingList';
import { useAnchorClick } from './useAnchorClick';
import { useKeyDown } from './useKeyDown';
import { useScrollingHeadingList } from './useScrollingHeadingList';

import styles from './styles.module.scss';

export interface TableOfContentsProps {
  title: string;
  toTheTop: string;
  headingListAriaLabel: string;
  headingList: Heading[];
}

const SCROLL_OFFSET_IN_PX = 8;
const BORDER_HEIGHT_IN_PIXELS = 2;
const DELAY_BEFORE_SCROLLING_MS = 50;
export const DELAY_BEFORE_SCROLLING_ON_LOAD_MS = 250;

export enum KeyboardEventKey {
  Enter = 'Enter',
  Escape = 'Escape',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Home = 'Home',
  End = 'End',
}

const TableOfContents: React.FunctionComponent<TableOfContentsProps> = ({ title, toTheTop, headingListAriaLabel, headingList }) => {
  const tableOfContentsRef = useRef<HTMLDivElement>(null);
  const headingListRef = useRef<HTMLOListElement>(null);
  const [tableOfContentsWidth, setTableOfContentsWidth] = useState<number>();
  const [selectedHeading, setSelectedHeading] = useState<Heading>();
  const { hoverRef: toggleButtonRef, isHovered: toggleButtonisHovered } = useHover<HTMLButtonElement>();
  const [isOpen, setIsOpen] = useState(true);
  const [heightChanged, setHeightChanged] = useState(false);
  const { currentHeading, scrollToHeading, scrollToElement, isSticky } = useScrollingHeadingList(
    headingList,
    toggleButtonRef,
    tableOfContentsRef
  );
  const previousIsSticky = usePrevious(isSticky);

  const toggleButtonSize = useSize(toggleButtonRef);
  const headingListSize = useSize(headingListRef);

  const scrollToTop = (): void => {
    window.location.href = `#${getSidetittelId()}`;
  };

  const goToHeading = (heading: Heading, immediate = false): void => {
    setIsOpen(false);
    // Når innholdsfortegnelsen skjules, mister den høyde, derfor må vi vente litt
    // før scrollingen faktisk starter slik at vi scroller til riktig sted
    setTimeout(
      () => {
        scrollToHeading(heading, immediate);
      },
      immediate ? DELAY_BEFORE_SCROLLING_ON_LOAD_MS : DELAY_BEFORE_SCROLLING_MS
    );
  };

  const goToElement = (element: HTMLElement, immediate = false): void => {
    setIsOpen(false);
    // Når innholdsfortegnelsen skjules, mister den høyde, derfor må vi vente litt
    // før scrollingen faktisk starter slik at vi scroller til riktig sted
    setTimeout(
      () => {
        scrollToElement(element, immediate);
      },
      immediate ? DELAY_BEFORE_SCROLLING_ON_LOAD_MS : DELAY_BEFORE_SCROLLING_MS
    );
  };

  useAnchorClick(goToElement);

  const handleHeadingClick = (event: React.MouseEvent, heading: Heading): void => {
    event.preventDefault();
    setHeightChanged(true);
    goToHeading(heading);
    heading.title && trackNavigation('innholdsfortegnelse', heading.title, 'innholdsfortegnelse');
  };

  const handleLayoutChange = (): void => {
    // Innholdsfortegnelsen kan være sticky, og bredden må da settes manuelt. Bruker bredden på parentElement for dette.
    const parentElementWidth = tableOfContentsRef?.current?.parentElement?.getBoundingClientRect()?.width;
    setTableOfContentsWidth(parentElementWidth);
  };

  useEffect(() => {
    const id = decodeURI(window.location.hash).substring(1);
    const initialHeading = headingList.find(({ slug }) => slug === id);
    const element = document.getElementById(id);
    if (initialHeading) {
      goToHeading(initialHeading, true);
    } else if (element) {
      goToElement(element, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // previousIsSticky og isSticky kan være undefined,
    // derfor en ekstra sjekk på at innholdsfortegnelsen
    // faktisk har byttet fra sticky til ikke-sticky
    if (previousIsSticky === true && !isSticky) {
      setIsOpen(true);
    } else if (!previousIsSticky && isSticky === true) {
      setIsOpen(false);
    }
    setHeightChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSticky]);

  const abortSelection = (): void => {
    setIsOpen(false);
    toggleButtonRef.current?.focus();
  };

  const selectHeading = (heading: Heading): void => {
    const index = headingList.findIndex(h => h === heading);
    const offsetTop = linkListItemRefList.current[index].current?.offsetTop;
    if (headingListRef.current && offsetTop) {
      headingListRef.current.scrollTop = offsetTop;
    }
    setSelectedHeading(heading);
  };

  // Høyden på innholdsfortegnelsen må settes manuelt slik at skygge/border blir riktig
  const tableOfContentsHeight = (toggleButtonSize?.height ?? 0) + (headingListSize?.height ?? 0) + BORDER_HEIGHT_IN_PIXELS;

  // Når innholdsfortegnelsen er sticky, mister den høyden sin. For at scrolling skal oppføre seg likt både som sticky
  // og ikke-sticky, må vi sette høyden på parent-elementet manuelt slik at "noe" tar opp plassen.
  // For å unngå hopping settes høyden på parent-elementet kun når høyden endres og er større
  if (tableOfContentsRef.current?.parentElement) {
    if ((!isSticky && tableOfContentsRef.current.parentElement.getBoundingClientRect().height < tableOfContentsHeight) || heightChanged) {
      tableOfContentsRef.current.parentElement.style.height = `${tableOfContentsHeight}px`;
    }
  }

  const handleKeyboardNavigation = (event: KeyboardEvent): void => {
    event.preventDefault();
    const index = headingList.findIndex(h => h === selectedHeading);
    if (!isOpen) {
      setIsOpen(true);
    } else if (event.key === KeyboardEventKey.Enter && selectedHeading) {
      goToHeading(selectedHeading);
    } else if (event.key === KeyboardEventKey.Escape) {
      abortSelection();
    } else if (event.key === KeyboardEventKey.Home) {
      selectHeading(headingList[0]);
    } else if (event.key === KeyboardEventKey.End) {
      selectHeading(headingList[headingList.length - 1]);
    } else if (event.key === KeyboardEventKey.ArrowDown && index < headingList.length - 1) {
      selectHeading(headingList[index + 1]);
    } else if (event.key === KeyboardEventKey.ArrowUp && index !== 0) {
      selectHeading(headingList[index - 1]);
    }
  };

  // Legg på scroll-padding-top når TOC er sticky for at elementer man fokuserer på ved keyboardnavigasjon skal scrolles helt inn i viewport
  useEffect(() => {
    if (isSticky) {
      document.documentElement.style.scrollPaddingTop = `${tableOfContentsHeight + SCROLL_OFFSET_IN_PX}px`;
    } else {
      document.documentElement.style.scrollPaddingTop = '';
    }
  }, [isSticky, tableOfContentsHeight]);

  useLayoutEvent(handleLayoutChange);
  useClickOutside(tableOfContentsRef, () => isSticky && setIsOpen(false));
  useKeyDown(
    tableOfContentsRef,
    [
      KeyboardEventKey.Enter,
      KeyboardEventKey.Escape,
      KeyboardEventKey.ArrowDown,
      KeyboardEventKey.ArrowUp,
      KeyboardEventKey.Home,
      KeyboardEventKey.End,
    ],
    handleKeyboardNavigation
  );

  const onToggleClick = (): void => {
    setHeightChanged(true);
    setIsOpen(!isOpen);
  };

  const linkListItemRefList = useRef(headingList.map(() => React.createRef<HTMLLIElement>()));

  const toggleClassName = cn(
    styles.tableofcontents__toggle,
    isOpen && styles['tableofcontents__toggle--open'],
    isSticky && styles['tableofcontents__toggle--sticky']
  );

  const listClassName = cn(
    styles.tableofcontents__list,
    isOpen && styles['tableofcontents__list--open'],
    isSticky && styles['tableofcontents__list--sticky'],
    'anchorlink-wrapper'
  );

  return (
    <div
      className={cn(styles.tableofcontents, isSticky && styles['tableofcontents--sticky'])}
      ref={tableOfContentsRef}
      style={{ width: tableOfContentsWidth, height: tableOfContentsHeight }}
    >
      <button className={toggleClassName} onClick={onToggleClick} ref={toggleButtonRef} aria-haspopup="listbox" aria-expanded={isOpen}>
        <Icon svgIcon={List} isHovered={toggleButtonisHovered} size={IconSize.XSmall} />
        <span className={styles['tableofcontents__title']}>{title}</span>
        {isSticky && (
          <Icon
            svgIcon={isOpen ? ChevronUp : ChevronDown}
            isHovered={toggleButtonisHovered}
            size={IconSize.XSmall}
            className={styles['tableofcontents__expanded-icon']}
          />
        )}
      </button>
      <ul
        className={listClassName}
        role="listbox"
        tabIndex={-1}
        aria-label={headingListAriaLabel}
        aria-activedescendant={currentHeading?.optionId}
        ref={headingListRef}
      >
        {isSticky && (
          // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
          <li className={cn(styles.tableofcontents__option)} role="option">
            <Button onClick={scrollToTop} variant="borderless">
              {toTheTop} <Icon svgIcon={ChevronUp} />
            </Button>
          </li>
        )}
        {headingList.map((heading, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <li
            key={index}
            id={heading.optionId}
            className={cn(
              styles.tableofcontents__option,
              styles.tableofcontents__link,
              currentHeading === heading && styles['tableofcontents__link--active'],
              selectedHeading === heading && styles['tableofcontents__link--selected']
            )}
            role="option"
            aria-selected={currentHeading === heading}
            onClick={(event): void => handleHeadingClick(event, heading)}
            ref={linkListItemRefList.current[index]}
          >
            {heading.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TableOfContents;
