import React from 'react';

import PromoSection, { PromoSectionColor } from '@helsenorge/core-cms/promosection';

export interface FeaturedItemBlockProps {
  url: string;
  title: string;
  intro: string;
  showImage: boolean;
  hideIntro: boolean;
  copyright: string;
  imageUrl: string;
  imageType: 'illustration' | 'photo';
  alternativeText: string;
  color: PromoSectionColor;
}

const FeaturedItemBlock: React.FC<FeaturedItemBlockProps> = props => (
  <PromoSection
    linkUrl={props.url}
    title={props.title}
    description={!props.hideIntro ? props.intro : undefined}
    imageUrl={props.showImage ? props.imageUrl : undefined}
    imageAltText={props.showImage ? props.alternativeText : undefined}
    imageCaption={props.showImage ? props.copyright : undefined}
    imageType={props.showImage ? props.imageType : undefined}
    color={props.color}
  />
);

export default FeaturedItemBlock;
