import React, { useState } from 'react';

import Button from '@helsenorge/designsystem-react/components/Button';
import { IconName } from '@helsenorge/designsystem-react/components/Icons/IconNames';
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import Modal from '@helsenorge/designsystem-react/components/Modal';

import styles from './styles.module.scss';

interface HandlingspanelLinkProps {
  title: string;
  icon?: IconName;
  popupTitle: string;
  popupIntro: string;
  popupIcon?: IconName;
  popupButtonLink?: string;
  popupButtonText: string;
  popupCancelText: string;
  closeButtonText: string;
}

const HandlingspanelLink: React.FunctionComponent<HandlingspanelLinkProps> = ({
  title,
  icon,
  popupTitle,
  popupIntro,
  popupIcon,
  popupButtonLink,
  popupButtonText,
  popupCancelText,
  closeButtonText,
}) => {
  const [showModal, setShowModal] = useState(false);

  const goToLink = (): void => {
    popupButtonLink && window.location.assign(popupButtonLink);
  };

  return (
    <div className={styles['handlingspanel-link']}>
      {showModal && (
        <Modal
          title={popupTitle}
          primaryButtonText={popupButtonText}
          secondaryButtonText={popupCancelText}
          onSuccess={(): void => goToLink()}
          onClose={(): void => setShowModal(false)}
          ariaLabelCloseBtn={closeButtonText}
          icon={popupIcon ? <LazyIcon iconName={popupIcon} /> : undefined}
        >
          <p className={styles['handlingspanel-link__description']}>{popupIntro}</p>
        </Modal>
      )}
      <Button onClick={(): void => setShowModal(!showModal)} fluid={true} variant="outline" arrow="icon">
        {icon && <LazyIcon iconName={icon} />}
        {title}
      </Button>
    </div>
  );
};

export default HandlingspanelLink;
