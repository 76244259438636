import { getTimeInMilliseconds, isTimestampOlderThan } from './time-utils';

const HIDE_CHATBOT_BUTTON_KEY = 'chatbot-button-closed';
const HIDE_BUTTON_SECONDS = 10 * 60; // 10 minutes

export class ButtonStore {
  /**
   * Tidspunkt der chatknappen ble skjult
   * @returns timestamp i ms
   */
  static getHiddenAt = (): number => parseInt(window.sessionStorage.getItem(HIDE_CHATBOT_BUTTON_KEY) as string, 10);

  /**
   * Sett tidspunkt for skjuling av chatknappen
   * @param timestamp timestamp i ms
   * @returns void
   */
  static setHiddenAt = (timestamp: string): void => window.sessionStorage.setItem(HIDE_CHATBOT_BUTTON_KEY, timestamp);

  /**
   * Fjern tidspunkt fra session storage
   * @returns void
   */
  static removeHiddenAt = (): void => window.sessionStorage.removeItem(HIDE_CHATBOT_BUTTON_KEY);

  /**
   * Sjekk om knappen har blitt skjult
   * @returns true dersom knappen har blitt skjult siste 10 min
   */
  static isRecentlyClosed = (): boolean => {
    if (isTimestampOlderThan(this.getHiddenAt(), HIDE_BUTTON_SECONDS)) {
      return true;
    }
    return false;
  };

  /**
   * Skjul knappen
   * @returns void
   */
  static hide = (): void => {
    this.setHiddenAt(getTimeInMilliseconds().toString());
  };
}

const CONVERSATION_ID_KEY = 'boostAiChatId';
const CONVERSATION_STARTED_KEY = 'boostAiChatTimestamp';
const CONVERSATION_EXPIRES_SECONDS = 12 * 60 * 60; // 12 hours

export class ConversationStore {
  /**
   * Hent id til chaten
   * @returns id til chaten
   */
  static getId = (): string | null => window.sessionStorage.getItem(CONVERSATION_ID_KEY);

  /**
   * Sett id til chaten
   * @param id til chaten
   * @returns void
   */
  static setId = (id: string): void => window.sessionStorage.setItem(CONVERSATION_ID_KEY, id);

  /**
   * Fjern id fra session storage
   * @returns void
   */
  static removeId = (): void => window.sessionStorage.removeItem(CONVERSATION_ID_KEY);

  /**
   * Hent tidspunkt der chaten ble started
   * @returns timestamp i ms
   */
  static getStartedAt = (): number => parseInt(window.sessionStorage.getItem(CONVERSATION_STARTED_KEY) as string, 10);

  /**
   * Sett tidspunkt for start av chaten
   * @param timestamp timestamp i ms
   * @returns void
   */
  static setStartedAt = (timestamp: string): void => window.sessionStorage.setItem(CONVERSATION_STARTED_KEY, timestamp);

  /**
   * Fjern tidspunkt fra session storage
   * @returns void
   */
  static removeStartedAt = (): void => window.sessionStorage.removeItem(CONVERSATION_STARTED_KEY);

  /**
   * Sjekk om chaten har startet for lenge siden
   * @returns true dersom chaten har gått ut
   */
  static isExpired = (): boolean => {
    if (isTimestampOlderThan(this.getStartedAt(), CONVERSATION_EXPIRES_SECONDS)) {
      return false;
    }
    return true;
  };

  /**
   * Sjekk om chaten har gått ut på dato, og fjern eventuelt data fra session storage
   */
  static checkExpiration = (): void => {
    if (this.isExpired()) {
      this.removeId();
      this.removeStartedAt();
    }
  };

  /**
   * Lagre at chaten har startet i session storage
   * @param id til chaten
   */
  static start = (id: string): void => {
    this.setId(id);
    this.setStartedAt(getTimeInMilliseconds().toString());
  };
}
