/* eslint-disable react/prop-types */
import { PortableTextTypeComponent } from '@portabletext/react';

import LinkBlockComponent, { LinkBlockProps } from './LinkBlock';

export interface LinkBlockType extends LinkBlockProps {
  _type: 'LinkBlock';
}

const LinkBlock: PortableTextTypeComponent<LinkBlockType> = ({ value }) => <LinkBlockComponent {...value} />;

export default LinkBlock;
