import { useEffect, useState } from 'react';

import { useIsServerSide } from '@helsenorge/designsystem-react/hooks/useIsServerSide';

import { hasLoggedInCookie, hasCookie } from '@helsenorge/framework-utils/cookie';
import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import { VisPersonvelgerDetail } from '@helsenorge/framework-utils/web-component/events';

/**
 * Elementer som skal skjules mens personvelgeren vises må matche denne CSS-selectoren.
 */
const HIDE_CONTENT_WRAPPER_SELECTOR = '.js-personvelger-hide';

/**
 * Klassenavn som vil bli brukt for å skjule elementer mens personvelgeren vises.
 */
const HIDE_CONTENT_CLASS_NAME = 'd-none';

/**
 * Klassenavn som legges til document.body for riktig bakgrunnsfarge mens personvelgeren vises.
 */
const PERSONVELGER_BODY_CLASS_NAME = 'body--personvelgerpage';

export const Personvelger = (): JSX.Element | null => {
  const [visPersonvelger, setVisPersonvelger] = useState(false);
  const isServerSide = useIsServerSide();

  const handleVisPersonvelgerUpdate = (event: CustomEvent<VisPersonvelgerDetail>): void => {
    setVisPersonvelger(event.detail.visPersonvelger);
  };

  useEffect(() => {
    // Personvelger skal bare vises dersom man er logget inn og ikke har valgt representasjon
    setVisPersonvelger(hasLoggedInCookie() && !hasCookie('HN-ValgtRepresentasjon'));
    window.addEventListener(HeaderFooterEvents.setvispersonvelger, handleVisPersonvelgerUpdate);

    return (): void => {
      window.removeEventListener(HeaderFooterEvents.setvispersonvelger, handleVisPersonvelgerUpdate);
    };
  }, []);

  if (isServerSide) {
    return null;
  }

  if (!visPersonvelger) {
    document.body.classList.remove(PERSONVELGER_BODY_CLASS_NAME);
    const elements = document.querySelectorAll(HIDE_CONTENT_WRAPPER_SELECTOR);
    elements.forEach(element => element.classList.remove(HIDE_CONTENT_CLASS_NAME));

    return null;
  }

  document.body.classList.add(PERSONVELGER_BODY_CLASS_NAME);
  const elements = document.querySelectorAll(HIDE_CONTENT_WRAPPER_SELECTOR);
  elements.forEach(element => element.classList.add(HIDE_CONTENT_CLASS_NAME));

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <hn-webcomp-personvelger-cms />
        </div>
      </div>
    </div>
  );
};

export default Personvelger;
