export const slugify = (string: string | null): string => {
  return (
    string
      ?.toLowerCase()
      .trim()
      .replace(/[-,.?!:]+/g, '') // Remove special characters
      // Erstatt norske tegn
      .replace(/æ/g, 'a')
      .replace(/ø/g, 'o')
      .replace(/å/g, 'a')
      // Erstatt samiske tegn
      .replace(/á/g, 'a')
      .replace(/č/g, 'c')
      .replace(/đ/g, 'd')
      .replace(/ŋ/g, 'n')
      .replace(/š/g, 's')
      .replace(/ŧ/g, 't')
      .replace(/ž/g, 'z')
      .replace(/\s+/g, '-') ?? '' // Replace spaces with -
  );
};

// Remove social security number from string
export const removeSsn = (string: string): string => {
  return string.replace(/[0-9]{2}[0,1][0-9][0-9]{2}[ ]?[0-9]{5}/, '*fødselsnummer*');
};

export const isEmail = (email: string): boolean => {
  // W3C email regex used for input type="email"
  const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regex.test(email);
};

// Compare two strings for locale-aware alphabetic sort
export const sortAlphabetically = (a: string, b: string): number => a.localeCompare(b);

// Reduce list of strings, leaving only unique strings
export const toUniqueValues = (unique: string[], item: string | null): string[] =>
  item && !unique.includes(item) ? [...unique, item] : unique;

// Check if string is in array (null-safe)
export const stringIsInArray = (needle: string | undefined, haystack: string[] | null): boolean =>
  !!needle && haystack != null && haystack.includes(needle);

type ObjectShape = {
  [name: string]: string | null;
};

// Check if string is in array of objects (null-safe)
export const stringIsInArrayOfObjects = <T extends ObjectShape>(
  needle: string | undefined,
  haystack: T[] | null,
  propertyName: keyof T
): boolean => !!needle && haystack != null && haystack.some(item => item[propertyName] === needle);

// Check if string equals string (null-safe)
export const stringEqualsString = (value: string | undefined, compare: string | null): boolean =>
  !!value && compare?.trim() === value.trim();

// Check if string includes string (for basic text searches)
export const stringIncludesString = (value: string | undefined, compare: string): boolean =>
  !!value && compare.trim().toLowerCase().includes(value.trim().toLowerCase());
