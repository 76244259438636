import React from 'react';

import Button from '@helsenorge/designsystem-react/components/Button';
import { ButtonProps } from '@helsenorge/designsystem-react/components/Button/Button';

interface LinkButtonProps extends ButtonProps {
  href: string;
}

const LinkButton: React.FunctionComponent<LinkButtonProps> = ({ children, ...rest }) => (
  <Button {...rest} htmlMarkup="a" arrow="icon">
    {children}
  </Button>
);

export default LinkButton;
