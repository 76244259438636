import { ButtonStore } from './store';

/**
 * If the chatbot button has been closed in the last 10 minutes, it should not be shown
 * untill these 10 minutes run out. Also show button if page has been refreshed.
 */
export const shouldShowChatbotButton = (): boolean => {
  const buttonHiddenAt = ButtonStore.getHiddenAt();

  if (buttonHiddenAt && !isNaN(buttonHiddenAt)) {
    // Benytter her PerformanceTiming api-et selv om det er deprecated siden det fortsatt er støttet av alle nettlesere
    // Det nye PerformanceNavigationTiming-apiet er støttet fra og Safari 15
    const isPageRefresh = window.performance && window.performance.navigation && window.performance.navigation.type === 1;
    const navigateFromSameOrigin = document.referrer.startsWith(window.origin);

    if (ButtonStore.isRecentlyClosed() && navigateFromSameOrigin && !isPageRefresh) {
      return false;
    } else {
      ButtonStore.removeHiddenAt();
    }
  }

  return true;
};
