import LinkList from '@helsenorge/core-cms/linklistblock/linklist';
import TjenesteLinkList from '@helsenorge/core-cms/linklistblock/tjenestelinklist';

import LinkListBlockWithSearch from './LinkListBlockWithSearch';

globalThis.LinkListComponents = {
  LinkList,
  LinkListBlockWithSearch,
  TjenesteLinkList,
};
