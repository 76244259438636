import React from 'react';

import classNames from 'classnames';

import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import ChevronDown from '@helsenorge/designsystem-react/components/Icons/ChevronDown';
import ChevronUp from '@helsenorge/designsystem-react/components/Icons/ChevronUp';
import { useToggle } from '@helsenorge/designsystem-react/hooks/useToggle';

interface AlphabetPanelProps {
  alphabetPanelButtonText: string;
  handleLetterClick: (event: React.MouseEvent<HTMLAnchorElement>, letter: string) => void;
  currentLetter?: string;
  alphabetPanelTitle: string;
  alphabetLetterList: string[];
  letterList: string[];
}

const AlphabetPanel = React.forwardRef<HTMLDivElement, AlphabetPanelProps>(
  ({ alphabetPanelButtonText, handleLetterClick, currentLetter, alphabetPanelTitle, alphabetLetterList, letterList }, ref) => {
    const { value: hideLetters, toggleValue: toggleLetters } = useToggle(true);

    return (
      <div className="alphabet-panel py-6 mt-6 bg-blueberry50 d-print-none" ref={ref}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <Button
                variant="borderless"
                wrapperClassName="d-block d-md-none"
                concept="normal"
                onClick={(): void => toggleLetters()}
                aria-expanded={!hideLetters}
              >
                <Icon svgIcon={hideLetters ? ChevronDown : ChevronUp} />
                {alphabetPanelButtonText}
              </Button>
              <nav
                aria-label={alphabetPanelTitle}
                className={classNames(
                  'alphabet-panel__nav',
                  {
                    'd-none': hideLetters,
                  },
                  'd-md-flex',
                  'mt-6',
                  'mt-md-0'
                )}
              >
                {alphabetLetterList.map((letter, index) => (
                  <span className="alphabet-panel__letter-container mb-5" key={letter}>
                    {letterList.includes(letter) ? (
                      <a
                        href={`#${letter}`}
                        onClick={(event): void => handleLetterClick(event, letter)}
                        className={classNames('alphabet-panel__letter alphabet-panel__letter--link', {
                          'alphabet-panel__letter--numeric': letter === '0-9',
                          'alphabet-panel__letter--active':
                            letter === currentLetter || // Highlight active letter
                            (!currentLetter && index === 0), // Highlight first letter on page load
                        })}
                      >
                        {letter}
                      </a>
                    ) : (
                      <span
                        className={classNames('alphabet-panel__letter', {
                          'alphabet-panel__letter--numeric': letter === '0-9',
                        })}
                      >
                        {letter}
                      </span>
                    )}
                  </span>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

AlphabetPanel.displayName = 'AlphabetPanel';

export default AlphabetPanel;
