import { slugify } from '../../_helpers/string';

export interface Heading {
  slug: string;
  optionId?: string;
  title?: string;
  element?: HTMLElement;
}

export const getHeadingList = (selector: string): Heading[] => {
  const headingList: HTMLHeadingElement[] = [];
  document.querySelectorAll<HTMLHeadingElement>(selector).forEach(h => headingList.push(h));

  return [...headingList]
    .filter(heading => heading?.textContent?.trim().length)
    .map(heading => {
      const slug = slugify(heading.textContent);
      heading.id = slug;
      heading.setAttribute('tabindex', '-1');

      return { slug, element: heading };
    });
};
