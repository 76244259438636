import { createRef, RefObject } from 'react';

export const updateHitsRefLength = <T>(refCount: number, maxCount?: number): RefObject<T>[] => {
  const refArray: RefObject<T>[] = [];
  if (!refCount) return refArray;

  const totalCount = maxCount && refCount > maxCount ? maxCount : refCount;
  for (let i = 0; i < totalCount; i++) refArray.push(createRef());

  return refArray;
};
