import React, { useState, useEffect, useRef } from 'react';

import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import PlusLarge from '@helsenorge/designsystem-react/components/Icons/PlusLarge';
import Label from '@helsenorge/designsystem-react/components/Label';
import LinkList from '@helsenorge/designsystem-react/components/LinkList';
import RadioButton from '@helsenorge/designsystem-react/components/RadioButton';
import { useIsServerSide } from '@helsenorge/designsystem-react/hooks/useIsServerSide';

import Filter from '@helsenorge/filter/components/filter';
import FilterSearch from '@helsenorge/filter/components/filter/filter-search';
import { useFilters } from '@helsenorge/filter/components/filter/useFilters';

import { getUrlParams, getTimestamp, setUrlParam, getConceptIdFromUrl } from './helpers';
import { ClinicalTrial, getClinicalTrials, RelatertDiagnose } from '../../_api/ClinicalTrialsApi';
import { getSnomedConceptItem, SnomedConcept } from '../../_api/SnomedCTApi';
import { updateHitsRefLength } from '../../_helpers/refs';
import {
  toUniqueValues,
  stringIsInArray,
  stringEqualsString,
  stringIncludesString,
  sortAlphabetically,
  stringIsInArrayOfObjects,
} from '../../_helpers/string';

const LOCAL_STORAGE_KEY = 'kliniskeStudier';
const ONE_HOUR = 60 * 60;
export const CLINICAL_TRIALS_ID = 'studier';

interface ClinicalTrialsBlockProps {
  title: string;
  clinicalTrialsApiUrl: string;
  snowstormApiUrl: string;
  snomedCTBranch: string;
  resources?: {
    loadingError?: string;
    search?: string;
    category?: string;
    status?: string;
    relevantTreatment?: string;
    responsibleOrganization?: string;
    studyTakesPlaceAt?: string;
    searchFilteredBy?: string;
    resetFilters?: string;
    singleClinicalTrial?: string;
    multipleClinicalTrials?: string;
    showMore?: string;
  };
  trialsPerPage?: number;
}

export interface ClinicalTrialsStorage {
  timestamp: number;
  kliniskeStudier: ClinicalTrial[];
}

export type ClinicalTrialsForm = {
  kategori: string;
  status: string;
  relatertBehandling: string;
  utfortAv: string;
  deltakendeForetak: string;
  query: string;
};

const ClinicalTrialsBlock: React.FunctionComponent<ClinicalTrialsBlockProps> = ({
  title,
  clinicalTrialsApiUrl,
  snowstormApiUrl,
  snomedCTBranch,
  resources,
  trialsPerPage = 25,
}) => {
  const isServerSide = useIsServerSide();
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [kliniskeStudier, setkliniskeStudier] = useState<ClinicalTrial[]>([]);
  const [concept, setConcept] = useState<SnomedConcept>();

  const { filters, activeFilters, searchQuery, setSearchQuery, setFilter } = useFilters<ClinicalTrialsForm>({
    defaultValues: {
      kategori: '',
      status: '',
      relatertBehandling: '',
      utfortAv: '',
      deltakendeForetak: '',
      query: '',
    },
  });

  useEffect(() => {
    const urlParams = getUrlParams();
    if (urlParams && setFilter) {
      setFilter('kategori', urlParams.kategori);
      setFilter('status', urlParams.status);
      setFilter('relatertBehandling', urlParams.relatertBehandling);
      setFilter('utfortAv', urlParams.utfortAv);
      setFilter('deltakendeForetak', urlParams.deltakendeForetak);
      setSearchQuery(urlParams.query);
    }
  }, []);

  useEffect(() => {
    filters?.kategori ? setUrlParam('kategori', filters.kategori) : setUrlParam('kategori', '');
    filters?.status ? setUrlParam('status', filters.status) : setUrlParam('status', '');
    filters?.relatertBehandling ? setUrlParam('relatertBehandling', filters.relatertBehandling) : setUrlParam('relatertBehandling', '');
    filters?.utfortAv ? setUrlParam('utfortAv', filters.utfortAv) : setUrlParam('utfortAv', '');
    filters?.deltakendeForetak ? setUrlParam('deltakendeForetak', filters.deltakendeForetak) : setUrlParam('deltakendeForetak', '');
  }, [filters]);

  useEffect(() => {
    searchQuery ? setUrlParam('query', searchQuery) : setUrlParam('query', '');
  }, [searchQuery]);

  useEffect(() => {
    const diagnose = getConceptIdFromUrl();
    if (diagnose) {
      getSnomedConceptItem(diagnose, snowstormApiUrl, snomedCTBranch)
        .then(concept => {
          if (concept) {
            setConcept(concept);
          }
        })
        .catch(error => setError(error));
    }
    try {
      const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (storedData) {
        const json = JSON.parse(storedData) as ClinicalTrialsStorage;
        if (getTimestamp() - json.timestamp > ONE_HOUR) {
          throw Error('Local storage data has expired');
        }
        if (!json.kliniskeStudier.length) {
          throw Error('Local storage data is invalid');
        }
        setkliniskeStudier(json.kliniskeStudier);
      } else {
        throw Error('Local storage has no data');
      }
    } catch (error) {
      getClinicalTrials(clinicalTrialsApiUrl)
        .then(trials => {
          if (trials) {
            setkliniskeStudier(trials);
          }
        })
        .catch(() => {
          setError(resources?.loadingError ?? 'Error: Could not load clinical trials');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicalTrialsApiUrl, resources, snomedCTBranch, snowstormApiUrl]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ timestamp: getTimestamp(), kliniskeStudier }));
  }, [kliniskeStudier]);

  const kategorier = kliniskeStudier
    .map(({ kategorier }) => kategorier)
    .flat()
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const statuser = kliniskeStudier
    .map(({ status }) => status)
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const relaterteBehandlinger = kliniskeStudier
    .map(({ relaterte_behandlinger: behandlinger }) => behandlinger ?? [])
    .flat()
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const utfortAv = kliniskeStudier
    .map(({ utfort_av: utfortAv }) => utfortAv)
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const deltakendeForetak = kliniskeStudier
    .map(({ deltakende_foretak: deltakendeForetak }) => deltakendeForetak ?? [])
    .flat()
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const filtrerteKliniskeStudier = kliniskeStudier
    .filter(x => !concept?.id || stringIsInArrayOfObjects<RelatertDiagnose>(concept?.id, x.relaterte_diagnoser, 'diagnose_kode'))
    .filter(x => !filters?.status || stringEqualsString(filters.status, x.status))
    .filter(x => !filters?.utfortAv || stringEqualsString(filters.utfortAv, x.utfort_av))
    .filter(x => !filters?.kategori || stringIsInArray(filters.kategori, x.kategorier))
    .filter(x => !filters?.relatertBehandling || stringIsInArray(filters.relatertBehandling, x.relaterte_behandlinger))
    .filter(x => !filters?.deltakendeForetak || stringIsInArray(filters.deltakendeForetak, x.deltakende_foretak))
    .filter(x => !searchQuery || stringIncludesString(searchQuery, `${x.tittel} ${x.sammendrag}`));

  const filtrerteKategorier = filtrerteKliniskeStudier
    .map(({ kategorier }) => kategorier)
    .flat()
    .reduce(toUniqueValues, [] as string[]);

  const filtrerteStatuser = filtrerteKliniskeStudier.map(({ status }) => status).reduce(toUniqueValues, [] as string[]);

  const filtrerteRelaterteBehandlinger = filtrerteKliniskeStudier
    .map(({ relaterte_behandlinger: behandlinger }) => behandlinger ?? [])
    .flat()
    .reduce(toUniqueValues, [] as string[]);

  const filtrerteUtfortAv = filtrerteKliniskeStudier.map(({ utfort_av: utfortAv }) => utfortAv).reduce(toUniqueValues, [] as string[]);

  const filtrerteDeltakendeForetak = filtrerteKliniskeStudier
    .map(({ deltakende_foretak: deltakendeForetak }) => deltakendeForetak ?? [])
    .flat()
    .reduce(toUniqueValues, [] as string[]);

  const linkRefList = useRef<React.RefObject<HTMLLIElement>[]>([React.createRef()]);

  linkRefList.current = updateHitsRefLength<HTMLLIElement>(filtrerteKliniskeStudier.length);

  const moreTrials = filtrerteKliniskeStudier.length > page * trialsPerPage;

  const handleShowMoreTrials = (): void => {
    setPage(page + 1);
  };

  useEffect(() => {
    // Set focus to the first of the newly added trials
    linkRefList?.current[(page - 1) * trialsPerPage]?.current?.focus();
  }, [page, trialsPerPage]);

  if (isServerSide) {
    return null;
  }

  if (error) return <p className="paragraph">{error}</p>;

  return (
    <div className="default-block-spacing" id={CLINICAL_TRIALS_ID}>
      <div className="mb-6">
        <Filter
          resources={{
            filterTitle: title ?? 'Find a clinical trial',
          }}
          isAlwaysOpen={false}
          showFilters
          activeFilters={activeFilters}
          searchFilter={
            <FilterSearch
              onChange={(e?: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e?.target.value ?? '')}
              defaultValue={searchQuery}
              onSearch={() => setSearchQuery(searchQuery ?? '')}
            />
          }
          filterOptions={[
            {
              type: 'dropdown',
              label: resources?.category ?? 'Category',
              placeholder: resources?.category ?? 'Category',
              children: kategorier.map(kategori => (
                <RadioButton
                  key={kategori}
                  name="kategori"
                  value={kategori}
                  checked={filters?.kategori === kategori}
                  disabled={!filtrerteKategorier.includes(kategori)}
                  label={<Label labelTexts={[{ text: kategori }]} />}
                  onChange={e => setFilter('kategori', e.target.checked ? e.target.value : '')}
                />
              )),
            },
            {
              type: 'dropdown',
              label: resources?.status ?? 'Status',
              placeholder: resources?.status ?? 'Status',
              children: statuser.map(status => (
                <RadioButton
                  key={status}
                  name="status"
                  value={status}
                  checked={filters?.status === status}
                  label={<Label labelTexts={[{ text: status }]} />}
                  disabled={!filtrerteStatuser.includes(status)}
                  onChange={e => setFilter('status', e.target.checked ? e.target.value : '')}
                />
              )),
            },
            {
              type: 'dropdown',
              label: resources?.relevantTreatment ?? 'Relevant treatment',
              placeholder: resources?.relevantTreatment ?? 'Relevant treatment',
              children: relaterteBehandlinger.map(behandling => (
                <RadioButton
                  key={behandling}
                  name="relatertBehandling"
                  value={behandling}
                  checked={filters?.relatertBehandling === behandling}
                  label={<Label labelTexts={[{ text: behandling }]} />}
                  disabled={!filtrerteRelaterteBehandlinger.includes(behandling)}
                  onChange={e => setFilter('relatertBehandling', e.target.checked ? e.target.value : '')}
                />
              )),
            },
            {
              type: 'dropdown',
              label: resources?.responsibleOrganization ?? 'Responsible organization',
              placeholder: resources?.responsibleOrganization ?? 'Responsible organization',
              children: utfortAv.map(foretak => (
                <RadioButton
                  key={foretak}
                  name="utfortAv"
                  value={foretak}
                  checked={filters?.utfortAv === foretak}
                  label={<Label labelTexts={[{ text: foretak }]} />}
                  disabled={!filtrerteUtfortAv.includes(foretak)}
                  onChange={e => setFilter('utfortAv', e.target.checked ? e.target.value : '')}
                />
              )),
            },
            {
              type: 'dropdown',
              label: resources?.studyTakesPlaceAt ?? 'Study takes place at',
              placeholder: resources?.studyTakesPlaceAt ?? 'Study takes place at',
              children: deltakendeForetak.map(foretak => (
                <RadioButton
                  key={foretak}
                  name="deltakendeForetak"
                  value={foretak}
                  checked={filters?.deltakendeForetak === foretak}
                  label={<Label labelTexts={[{ text: foretak }]} />}
                  disabled={!filtrerteDeltakendeForetak.includes(foretak)}
                  onChange={e => setFilter('deltakendeForetak', e.target.checked ? e.target.value : '')}
                />
              )),
            },
          ]}
        />
      </div>

      <div className="row bg-neutral50 pt-7 pb-8">
        <div className="col-12 col-md-10 offset-md-1">
          <p className="mt-0 mb-6 mb-md-7" role="alert" aria-live="polite">
            <strong>
              {filtrerteKliniskeStudier.length}{' '}
              {filtrerteKliniskeStudier.length === 1
                ? (resources?.singleClinicalTrial ?? 'clinical trial')
                : (resources?.multipleClinicalTrials ?? 'clinical trials')}
            </strong>
          </p>
          {filtrerteKliniskeStudier.length > 0 && (
            <LinkList color="neutral">
              {filtrerteKliniskeStudier.slice(0, page * trialsPerPage).map((kliniskStudie, index) => (
                <LinkList.Link key={kliniskStudie.id} ref={linkRefList.current[index]} href={kliniskStudie.lenke}>
                  {kliniskStudie.tittel}
                </LinkList.Link>
              ))}
            </LinkList>
          )}
          {moreTrials && (
            <Button concept="normal" variant="outline" onClick={handleShowMoreTrials} wrapperClassName="mt-6 ml-3 ml-md-9">
              <Icon svgIcon={PlusLarge} />
              {resources?.showMore ?? 'Show more'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClinicalTrialsBlock;
