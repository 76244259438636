import React from 'react';

import { IconName } from '@helsenorge/designsystem-react/components/Icons/IconNames';

import Panel, { PanelProps } from '@helsenorge/core-cms/panel';

export interface LinkBlockProps extends PanelProps {
  icon: IconName;
}

const LinkBlock: React.FunctionComponent<LinkBlockProps> = ({ icon, ...rest }) => <Panel icon={icon} {...rest} />;

export default LinkBlock;
