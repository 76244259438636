import React from 'react';

import { PortableTextComponents } from '@portabletext/react';
import { PortableTextBlock } from '@portabletext/types';
import classNames from 'classnames';

import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import ChevronDown from '@helsenorge/designsystem-react/components/Icons/ChevronDown';
import ChevronUp from '@helsenorge/designsystem-react/components/Icons/ChevronUp';
import { IconName } from '@helsenorge/designsystem-react/components/Icons/IconNames';
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import Title from '@helsenorge/designsystem-react/components/Title';

import ResourceBlock from '@helsenorge/core-cms/behandlingsvalg/ressursblock';
import PortableText from '@helsenorge/core-cms/richtext';
import { globalComponents } from '@helsenorge/core-cms/richtext/portable-text-utils';

import { useToggle } from '../../_helpers/hooks';
import FeaturedItemBlock from '../FeaturedItem/FeaturedItemBlock';
import FeaturedItemBlockPortableText, { FeaturedItemBlockType } from '../FeaturedItem/FeaturedItemBlockPortableText';
import LinkBlock from '../Link/LinkBlock';
import LinkBlockPortableText, { LinkBlockType } from '../Link/LinkBlockPortableText';
import ResourceBlockPortableText, { ResourceBlockType } from '../Resource/ResourceBlockPortableText';

type OtherContentType = LinkBlockType | ResourceBlockType | FeaturedItemBlockType;

interface VeiviserTrinnBlockProps {
  content: PortableTextBlock[];
  introduction: string;
  exampleText?: PortableTextBlock[];
  link: string;
  linkText: string;
  linkIcon?: IconName;
  title: string;
  openText: string;
  closeText: string;
  otherContent?: OtherContentType[];
}

const veiviserComponents: PortableTextComponents = {
  types: {
    ...globalComponents.types,
    FeaturedItemBlock: FeaturedItemBlockPortableText,
    ResourceBlock: ResourceBlockPortableText,
    LinkBlock: LinkBlockPortableText,
  },
};

const VeiviserTrinnBlock: React.FunctionComponent<VeiviserTrinnBlockProps> = ({
  content,
  introduction,
  exampleText,
  link,
  linkText,
  linkIcon,
  title,
  openText,
  closeText,
  otherContent,
}) => {
  const { value: isOpen, toggleValue: toggleChange } = useToggle(false);

  const renderOtherContent = (block: OtherContentType): React.ReactNode => {
    switch (block._type) {
      case 'LinkBlock':
        return <LinkBlock {...block} />;
      case 'ResourceBlock':
        return <ResourceBlock {...block} />;
      case 'FeaturedItemBlock':
        return <FeaturedItemBlock {...block} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Title htmlMarkup="h2" appearance="title3" className="mb-5 pt-md-5">
        {title}
      </Title>

      {introduction && <p className="paragraph">{introduction}</p>}

      {link && linkText && (
        <Button htmlMarkup="a" href={link} concept="normal" size={linkIcon ? 'large' : 'medium'} arrow="icon">
          {linkIcon && <LazyIcon iconName={linkIcon} />}
          {linkText}
        </Button>
      )}
      {(content || exampleText || (otherContent && otherContent.length > 0)) && (
        <div className="mt-5">
          <Button
            concept="normal"
            variant="borderless"
            wrapperClassName={classNames({ 'd-print-none': isOpen })}
            onClick={() => toggleChange()}
            aria-expanded={isOpen}
            data-testid="read-more"
          >
            {isOpen ? closeText : openText}
            <Icon svgIcon={isOpen ? ChevronUp : ChevronDown} />
          </Button>
          <div className={classNames({ 'd-none': !isOpen })}>
            <PortableText value={content} components={veiviserComponents} className="mt-6 " />
            {exampleText && <PortableText value={exampleText} components={veiviserComponents} className="p-6 mt-6 bg-plum50 rich-text" />}
            {otherContent &&
              otherContent.map((block, index) => (
                <div className="mt-6" key={index}>
                  {renderOtherContent(block)}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default VeiviserTrinnBlock;
