/* eslint-disable react/prop-types */
import { PortableTextTypeComponent } from '@portabletext/react';

import FeaturedItemBlockComponent, { FeaturedItemBlockProps } from './FeaturedItemBlock';

export interface FeaturedItemBlockType extends FeaturedItemBlockProps {
  _type: 'FeaturedItemBlock';
}

const FeaturedItemBlock: PortableTextTypeComponent<FeaturedItemBlockType> = ({ value }) => <FeaturedItemBlockComponent {...value} />;

export default FeaturedItemBlock;
