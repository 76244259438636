import React from 'react';

import classNames from 'classnames';

import TemainngangBlock, { TemainngangBlockProps } from './TemaInngang/TemaInngangBlock';

import styles from './styles.module.scss';

export enum ColumnCount {
  four = 3,
  three = 4,
}

export interface TemainngangListeBlockProps {
  links: TemainngangBlockProps[];
  /* Antall innganger som skal vises per rad */
  columnCount?: ColumnCount;
}

const TemainngangListeBlock: React.FunctionComponent<TemainngangListeBlockProps> = ({ links, columnCount = ColumnCount.four }) => {
  const classes = classNames(styles['temainngangliste__list-element'], 'col-12 col-md-6', {
    ['col-lg-3']: columnCount === ColumnCount.four,
    ['col-lg-4']: columnCount === ColumnCount.three,
  });
  return (
    <div>
      {links?.length > 0 && (
        <ul className={`${styles['temainngangliste__list']} row`}>
          {links.map((block, index) => (
            <li key={index} className={classes}>
              <TemainngangBlock {...block} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TemainngangListeBlock;
