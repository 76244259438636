import React from 'react';

import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import Zoom from '@helsenorge/designsystem-react/components/Icons/Zoom';

import { useHover } from '@helsenorge/designsystem-react';

import styles from './styles.module.scss';

interface ZoomButtonProps {
  ariaLabel: string;
  onClick: () => void;
}

const ZoomButton: React.FunctionComponent<ZoomButtonProps> = ({ ariaLabel, onClick }) => {
  const { hoverRef, isHovered } = useHover<HTMLButtonElement>();

  return (
    <button ref={hoverRef} className={styles.zoom} aria-label={ariaLabel} onClick={onClick} title={ariaLabel}>
      <Icon className={styles.zoom__icon} svgIcon={Zoom} size={IconSize.XSmall} isHovered={isHovered} />
    </button>
  );
};

export default ZoomButton;
