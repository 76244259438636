import React from 'react';

import { useForm } from 'react-hook-form';

import Button from '@helsenorge/designsystem-react/components/Button';
import FormGroup from '@helsenorge/designsystem-react/components/FormGroup';
import HighlightPanel from '@helsenorge/designsystem-react/components/HighlightPanel';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import PaperPlane from '@helsenorge/designsystem-react/components/Icons/PaperPlane';
import Input from '@helsenorge/designsystem-react/components/Input';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';
import Select from '@helsenorge/designsystem-react/components/Select';
import Textarea from '@helsenorge/designsystem-react/components/Textarea';
import Title from '@helsenorge/designsystem-react/components/Title';
import Validation from '@helsenorge/designsystem-react/components/Validation';

import { AdobeAnalyticsConstants as CoreCmsAdobeAnalyticsConstants } from '@helsenorge/core-cms/userfeedback/utils/analytics';
import { sanitizeValue } from '@helsenorge/core-cms/userfeedback/utils/string';
import { trackTool } from '@helsenorge/framework-utils/adobe-analytics';

import { sendEmail } from '../../_api/ContactApi';
import { AdobeAnalyticsConstants } from '../../_constants/adobeAnalyticsConstants';
import { removeSsn, isEmail } from '../../_helpers/string';
import SafeHTML from '../SafeHtml';

import styles from './styles.module.scss';

interface Category {
  title: string;
  description: string;
}
interface ContactForm {
  title?: string;
  introduction?: string;
  categoryLabel: string;
  categoryValidationMessage: string;
  chooseCategoryLabel: string;
  categories: Category[];
  nameLabel: string;
  emailLabel: string;
  emailValidationMessage: string;
  feedbackLabel: string;
  feedbackValidationMessage: string;
  feedbackHelpText?: string;
  submitText: string;
  formValidationMessage: string;
  successMessage: string;
  sendEmailUrl: string;
}

interface ContactFormProps {
  contactForm: ContactForm;
  feedbackFieldMaxCharacters: number;
  resources?: {
    submitError?: string;
    characters: string;
  };
}

interface ContactFormData {
  date: string;
  category: string;
  name: string;
  email: string;
  feedback: string;
}

const ContactForm: React.FunctionComponent<ContactFormProps> = ({ contactForm, feedbackFieldMaxCharacters, resources }) => {
  const {
    register,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    handleSubmit,
    setError,
    watch,
  } = useForm<ContactFormData>({
    defaultValues: {
      date: '',
      category: '',
      name: '',
      email: '',
      feedback: '',
    },
  });

  const {
    title,
    introduction,
    categoryLabel,
    chooseCategoryLabel,
    categories,
    nameLabel,
    emailLabel,
    feedbackLabel,
    feedbackHelpText,
    submitText,
    categoryValidationMessage,
    emailValidationMessage,
    feedbackValidationMessage,
    formValidationMessage,
    successMessage,
    sendEmailUrl,
  } = contactForm;

  const maxFeedbackLength = feedbackFieldMaxCharacters || 500;

  const category = watch('category');
  const { description } = categories.find(c => c.title === category) || {};

  const onSubmit = async (data: ContactFormData): Promise<void> => {
    trackTool(
      AdobeAnalyticsConstants.Kontaktskjema,
      CoreCmsAdobeAnalyticsConstants.Feedback,
      data.category,
      AdobeAnalyticsConstants.Submit
    );
    try {
      await sendEmail({ sendEmailUrl, ...data });
    } catch {
      setError('root', {
        type: 'fetch',
        message: resources?.submitError,
      });
    }
  };

  if (isSubmitSuccessful) {
    return (
      <HighlightPanel size="fluid" color="blueberry" svgIcon={PaperPlane}>
        {successMessage}
      </HighlightPanel>
    );
  }

  if (errors.root?.message) {
    return (
      <NotificationPanel variant="error" role="alert">
        {errors.root?.message}
      </NotificationPanel>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} method="post">
      <input
        type="text"
        name="date"
        id="date"
        title="date"
        className={styles.contactform__date}
        aria-hidden="true"
        tabIndex={-1}
        autoComplete="off"
      />

      {title && (
        <Title htmlMarkup="h2" appearance="title2">
          {title}
        </Title>
      )}

      {introduction && <p>{introduction}</p>}

      <Validation errors={errors} errorTitle={formValidationMessage}>
        <FormGroup error={errors.category?.message}>
          <Select
            label={categoryLabel}
            selectId="category"
            disabled={isSubmitting}
            {...register('category', { required: categoryValidationMessage })}
          >
            <option value={''}>{chooseCategoryLabel}</option>
            {categories.map(({ title }) => (
              <option value={title} key={title}>
                {title}
              </option>
            ))}
          </Select>
        </FormGroup>

        {description && <SafeHTML className="rich-text" aria-live="polite" html={description} />}

        {!description && (
          <FormGroup>
            <Input
              label={nameLabel}
              inputId="name"
              disabled={isSubmitting}
              autoComplete="name"
              {...register('name', { setValueAs: v => removeSsn(sanitizeValue(v)) })}
            />
          </FormGroup>
        )}

        {!description && (
          <FormGroup error={errors.email?.message}>
            <Input
              label={emailLabel}
              inputId="email"
              type="email"
              disabled={isSubmitting}
              autoComplete="email"
              {...register('email', {
                required: emailValidationMessage,
                validate: v => isEmail(v) || emailValidationMessage,
                setValueAs: v => removeSsn(sanitizeValue(v)),
              })}
            />
          </FormGroup>
        )}

        {!description && feedbackHelpText && (
          <NotificationPanel labelId="feedbackHelpText" className={styles.contactform__help}>
            {feedbackHelpText}
          </NotificationPanel>
        )}
        {!description && (
          <FormGroup error={errors.feedback?.message} errorTextId="feedbackErrorText">
            <Textarea
              label={feedbackLabel}
              textareaId="feedback"
              aria-describedby={[
                errors.feedback?.message ? 'feedbackErrorText' : undefined,
                feedbackHelpText ? 'feedbackHelpText' : undefined,
              ]
                .filter(Boolean)
                .join(' ')}
              disabled={isSubmitting}
              maxCharacters={maxFeedbackLength}
              maxText={resources?.characters}
              grow
              {...register('feedback', {
                required: feedbackValidationMessage,
                maxLength: { value: maxFeedbackLength, message: feedbackValidationMessage },
                setValueAs: v => removeSsn(sanitizeValue(v)),
              })}
            />
          </FormGroup>
        )}
      </Validation>

      {!description && (
        <Button type="submit" size="large" disabled={isSubmitting}>
          <Icon svgIcon={PaperPlane} />
          {submitText}
        </Button>
      )}
    </form>
  );
};

export default ContactForm;
