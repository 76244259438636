import React, { forwardRef } from 'react';

import classnames from 'classnames';

import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import ArrowRight from '@helsenorge/designsystem-react/components/Icons/ArrowRight';
import Tag from '@helsenorge/designsystem-react/components/Tag';
import Title from '@helsenorge/designsystem-react/components/Title';

import { trackSearchThrough } from '@helsenorge/framework-utils/adobe-analytics';

import { getIconColor } from '../../_helpers/color';
import Dictionary, { DictionaryObject } from '../../_helpers/dictionary';

export interface SearchHitProps extends SearchHitDisplayProps {
  dictionary: DictionaryObject;
  intro: string;
  hitType: HitType;
  isMobile: boolean;
  searchRef: React.Ref<HTMLAnchorElement>;
}

type HitType = 'bestbet' | 'tjeneste' | 'verktoy' | 'regular';
interface SearchHitDisplayProps {
  imageData?: ImageData | null;
  index: number;
  title: string;
  url: string;
}

export interface ImageData {
  imageUrl: string;
  imageAlternativeText: string;
}

const SearchHit: React.FunctionComponent<SearchHitProps> = ({
  dictionary,
  imageData,
  index,
  intro,
  hitType,
  isMobile,
  searchRef,
  title,
  url,
}) => {
  const renderSearchHitTag = (
    <>
      {hitType == 'tjeneste' && (
        <div className="search-hit-tag">
          <Tag color="neutral" variant="emphasised">
            {Dictionary(dictionary, '/searchPage/service', 'Tjeneste')}
          </Tag>
        </div>
      )}
      {hitType == 'verktoy' && (
        <div className="search-hit-tag">
          <Tag color="cherry" variant="emphasised">
            {Dictionary(dictionary, '/searchPage/tool', 'Verktøy')}
          </Tag>
        </div>
      )}
    </>
  );

  const renderSearchHitHeading = (
    <>
      {renderSearchHitTag}
      <div className="search-hit-accessory d-flex align-items-center">
        <Icon size={IconSize.XSmall} color={getIconColor('blueberry')} svgIcon={ArrowRight} />
      </div>
      <Title htmlMarkup="h2" appearance="title3" className={classnames('search-hit-heading', intro ? 'mb-3' : '')}>
        {title}
      </Title>
    </>
  );

  const renderSearchHitBody = (
    <>
      <p className="paragraph">{intro}</p>
    </>
  );

  const renderSearchHitContent = (): JSX.Element => (
    <>
      <div className="col-sm-11 offset-sm-1">{renderSearchHitHeading}</div>
      <div className="col-sm-10 offset-sm-1">{renderSearchHitBody}</div>
    </>
  );

  const renderSearchHitWithImage = (imageData: ImageData): JSX.Element => (
    <div className="col-sm-11 offset-sm-1">
      <div className="row">
        <div className="col-md-5 col-lg-3">
          <div className="search-hit-image">
            <img src={imageData.imageUrl} alt={imageData.imageAlternativeText} />
          </div>
        </div>
        <div className="col-md-7 col-lg-9 py-7">
          <div className="row">
            <div className="col-12">{renderSearchHitHeading}</div>
            <div className="col-sm-11">{renderSearchHitBody}</div>
          </div>
        </div>
      </div>
    </div>
  );

  const SearchHitDisplay = forwardRef<HTMLAnchorElement, SearchHitDisplayProps>((props, ref) => (
    <a
      href={props.url}
      onClick={(): void => trackSearchThrough(props.index + 1)}
      key={`${props.title}-${props.index}`}
      ref={ref}
      className={classnames('search-hit col-lg-10 offset-lg-1 border-bottom-1', { 'border-top-1': props.index === 0 })}
    >
      {hitType == 'bestbet' && props.imageData?.imageUrl && !isMobile ? (
        <div className="row">{renderSearchHitWithImage(props.imageData)} </div>
      ) : (
        <div className="py-7">
          <div className="row">{renderSearchHitContent()}</div>
        </div>
      )}
    </a>
  ));

  SearchHitDisplay.displayName = 'SearchHitDisplay';

  const renderSearchHitLink = (): JSX.Element => {
    return <SearchHitDisplay url={url} title={title} index={index} imageData={imageData} ref={searchRef} />;
  };

  return renderSearchHitLink();
};

export default SearchHit;
