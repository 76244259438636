import React, { useState, useEffect } from 'react';

import Panel from '@helsenorge/core-cms/panel';
import { erHelsenorge } from '@helsenorge/framework-utils/hn-proxy-service';

import { getClinicalTrialsItem, ClinicalTrial } from '../../_api/ClinicalTrialsApi';
import { getSnomedConceptItem, SnomedConcept } from '../../_api/SnomedCTApi';
import { CLINICAL_TRIALS_ID } from '../ClinicalTrials/ClinicalTrialsBlock';

interface RelatedClinicalTrialsBlockProps {
  conceptId: string;
  clinicalTrialsUrl: string;
  clinicalTrialsApiUrl: string;
  snowstormApiUrl: string;
  snomedCTBranch: string;
  description: string;
  loadingErrorMessage: string;
}

const OPEN_FOR_REQRUITMENT_STATUS = 'Pågående, åpen for rekruttering';

const RelatedClinicalTrialsBlock: React.FunctionComponent<RelatedClinicalTrialsBlockProps> = ({
  conceptId,
  clinicalTrialsUrl,
  clinicalTrialsApiUrl,
  snowstormApiUrl,
  snomedCTBranch,
  description,
  loadingErrorMessage,
}) => {
  const [error, setError] = useState<string>();
  const [loaded, setLoaded] = useState(false);
  const [trials, setTrials] = useState<ClinicalTrial[]>([]);
  const [concept, setConcept] = useState<SnomedConcept>();

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      try {
        const [trials, concept] = await Promise.all([
          getClinicalTrialsItem(conceptId, clinicalTrialsApiUrl),
          getSnomedConceptItem(conceptId, snowstormApiUrl, snomedCTBranch),
        ]);

        const openTrials = trials?.filter(trial => trial.status === OPEN_FOR_REQRUITMENT_STATUS);
        if (openTrials && openTrials.length > 0 && concept) {
          setTrials(openTrials);
          setConcept(concept);
        }
      } catch {
        setError(loadingErrorMessage);
      } finally {
        setLoaded(true);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicalTrialsApiUrl, conceptId, snomedCTBranch, snowstormApiUrl]);

  if (error) {
    return erHelsenorge() ? null : <p className="paragraph text-cherry500 is-semibold">{error}</p>;
  }

  if (!clinicalTrialsUrl) {
    return <p className="paragraph">{'Feil: URL til side med kliniske studier er ikke definert'}</p>;
  }

  if (!loaded || trials.length === 0) {
    return null;
  }

  return (
    <Panel
      url={`${clinicalTrialsUrl}?diagnose=${conceptId}&status=${OPEN_FOR_REQRUITMENT_STATUS}#${CLINICAL_TRIALS_ID}`}
      title={concept?.pt.term}
      intro={description}
    />
  );
};

export default RelatedClinicalTrialsBlock;
