import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

export type LinkTargets = '_self' | '_blank' | '_parent';
export type TemaInngangColor = 'neutral' | 'cherry' | 'blueberry';

export interface TemainngangBlockProps {
  title: string;
  ingress?: string;
  image: string;
  url: string;
  linkTarget?: LinkTargets;
  backgroundColor?: TemaInngangColor;
  alternativeText: string;
}

const TemainngangBlock: React.FunctionComponent<TemainngangBlockProps> = ({
  title,
  ingress,
  image,
  url,
  linkTarget,
  alternativeText,
  backgroundColor = 'neutral',
}) => {
  return (
    <div className={classNames(styles['temainngang'], styles[`temainngang--${backgroundColor}`])}>
      <img src={image} className={styles['temainngang__image']} alt={alternativeText} />
      <div className={styles['temainngang__content']}>
        <a
          href={url}
          target={linkTarget}
          rel={linkTarget === '_blank' ? 'noopener noreferrer' : undefined}
          className={classNames(
            styles['temainngang__link'],
            ingress ? styles['temainngang__link--left'] : styles['temainngang__link--centered']
          )}
        >
          {title}
        </a>
        {ingress && <p className={styles['temainngang__ingress']}>{ingress}</p>}
      </div>
    </div>
  );
};

export default TemainngangBlock;
