import React from 'react';

import classNames from 'classnames';

import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';

import { theme } from '@helsenorge/designsystem-react';
import { useHover } from '@helsenorge/designsystem-react';

import ChevronUpWhiteLine from './ChevronUpWhiteLine';

import styles from './styles.module.scss';

export interface TabProps {
  isActive?: boolean;
  title: string;
  icon: React.ReactElement;
  className?: string;
  id?: string;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Tab: React.FC<TabProps> = ({ isActive = false, title, id, icon, handleClick }) => {
  const { hoverRef, isHovered } = useHover<HTMLButtonElement>();

  return (
    <button onClick={handleClick} className={styles.tab} id={id} ref={hoverRef} role="tab" aria-selected={isActive}>
      <span className={classNames(styles.tab__title, isActive && styles['tab__title--active'])}>
        {icon ? (
          <span>
            {React.cloneElement(icon, {
              size: IconSize.XSmall,
              isHovered,
              color: isActive ? theme.palette.black : theme.palette.blueberry600,
            })}
          </span>
        ) : null}
        {title}
      </span>
      <div className={styles['tab__icon-container']}>
        {isActive && <Icon className={styles.tab__icon} size={IconSize.Small} svgIcon={ChevronUpWhiteLine} />}
      </div>
    </button>
  );
};

export default Tab;
